// MyProvider.js
import React, { useEffect, useState } from 'react';
import AIOContext from './AIOContext';

const AIOProvider = ({ children }) => {
  const [interiorConfig, setInteriorConfig] = useState({});
  const [exteriorConfig, setExteriorConfig] = useState({});
  // const [dapChecked, setDapChecked] = useState(false);
  const [dueAmount, setDueAmount] = useState(10000);
  const [vehicleSubtotal, setVehicleSubtotal] = useState(0);
  // const [isFleet, setIsFleet] = useState(true);
  const [logoData, setLogoData] = useState('');
  // const [vehicleQuantities, setVehicleQuantities] = useState({
  //   car: 1,
  //   truck: 1,
  //   van: 1,
  // });

  const [userConfig, setUserConfig] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    emailAddress: '',
    dapPurchased: false,
    // amountPaid: 0,
    // stripePaymentID: '',
    // stripeLastFour: '',
    // stripePaymentType: '',
  });

  const [vehicleConfig, setVehicleConfig] = useState({
    colors: {
      body: {
        color: '#B8B8B8',
        material: 'Gloss',
      },
      front: {
        color: '#2D2D2D',
        material: 'Plastic',
      },
      rear: {
        color: '#2D2D2D',
        material: 'Fiber',
      },
      roof: {
        color: '#2D2D2D',
        material: 'Gloss',
      },
      rocket: {
        color: '#2D2D2D',
        material: 'Fiber',
      },
      window: {
        color: '#BCBCBC',
        material: 'Glass',
      },
      trim: {
        color: '#2D2D2D',
        material: 'Gloss',
      },
      grille: {
        color: '#2D2D2D',
        material: 'Metal',
      },
      cover: {
        color: '#D9D9D9',
        material: 'Metal',
      },
      rim: {
        color: '#D9D9D9',
        material: 'Gloss',
      },
      ceiling: {
        color: '#2D2D2D',
        material: 'Fiber',
      },
      screen: {
        color: '#2D2D2D',
        material: 'Matt',
      },
      seat: {
        color: '#2D2D2D',
        material: 'Gloss',
      },
      wall: {
        color: '#2D2D2D',
        material: 'Metal',
      },
      floor: {
        color: '#2D2D2D',
        material: 'Matt',
      },
    },
    logoUrl: './assets/images/AIO Logo.png',
    logoScale: 1,
    aeroCover: true,
    logoPosition: [0, 0, 0],
    carType: 'car',
    isInterior: false,
    isFleet: false,
    vehicleQuantities: {
      car: 1,
      truck: 1,
      van: 1,
    },
  });

  const totalVehicles = Object.values(vehicleConfig.vehicleQuantities).reduce(
    (total, quantity) => {
      if (isNaN(quantity)) {
        return total;
      }
      return total + parseInt(quantity, 10);
    },
    0
  );

  const totalVehicleTypesSelected = Object.values(
    vehicleConfig.vehicleQuantities
  ).reduce((total, quantity) => {
    if (quantity > 0) {
      return total + 1;
    } else {
      return total;
    }
  }, 0);

  const totalDapAmount = vehicleConfig.isFleet
    ? totalVehicleTypesSelected * 1000
    : 1000;

  const baseAmount = 10000;
  const baseDapCost = 1000;

  // automatically recalculate dueAmount whenever dapChecked or totalVehicles changes
  useEffect(() => {
    let amount = baseAmount;
    if (vehicleConfig.isFleet) {
      if (totalVehicles > 1) {
        amount = amount * totalVehicles;
      }
      setVehicleSubtotal(amount);
      if (userConfig.dapPurchased) {
        amount = amount + totalDapAmount;
      }
    } else {
      setVehicleSubtotal(amount);
      if (userConfig.dapPurchased) {
        amount = amount + baseDapCost;
      }
    }
    setDueAmount(amount);
  }, [userConfig.dapPurchased, totalVehicles, vehicleConfig, totalDapAmount]);

  const toggleDap = () => {
    setUserConfig({ ...userConfig, dapPurchased: !userConfig.dapPurchased });
    // setDapChecked(!dapChecked);
  };

  return (
    <AIOContext.Provider
      value={{
        interior: { interiorConfig, setInteriorConfig },
        exterior: { exteriorConfig, setExteriorConfig },
        user: { userConfig, setUserConfig },
        vehicle: { vehicleConfig, setVehicleConfig },
        dap: { dapChecked: userConfig.dapPurchased, toggleDap, totalDapAmount },
        dueAmount,
        fleet: {
          isFleet: vehicleConfig.isFleet,
          totalVehicles,
          totalVehicleTypesSelected,
          vehicleSubtotal,
        },
        logo: {
          logoData,
          setLogoData,
        },
      }}
    >
      {children}
    </AIOContext.Provider>
  );
};

export default AIOProvider;
