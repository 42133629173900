import React, { useState, useContext, useEffect } from 'react';
import { ChromePicker } from 'react-color';
import { Box, Grow, styled, Avatar, Typography, Collapse } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SubColorComp from './ColorComp/Comp/SubColorComp';
import SubMaterial from './ColorComp/Comp/SubMaterial';
import AIOContext from '../../AIOContext';
import SubColorPicker from './ColorComp/Comp/SubColorPicker';
import ColorPickerFloor from './ColorComp/Comp/ColorPickerFloor';

function InteriorComp(props) {
    const { vehicle } = useContext(AIOContext)
    const [color, setColor] = useState()
    const [mode, setMode] = useState(null)
    const [materialMode, setMaterialMode] = useState(false)
    const handleChangeComplete = (color) => {
        setColor(color.hex)
        props.threejsModule.updateColor(color)
    }

    const setModeHandler = (value) => {
        console.log('=== VALUE ===')
        console.log(value)
        if (value === mode) {
            setMode(null)
        } else {
            props.threejsModule.activeMaterial(value)
            setMode(value)
        }
    }

    const handleClose = () => {

        props.setConfigureMode(null)
    }

    useEffect(() => {
        // vehicle.setVehicleConfig(() => ({
        //     ...props.configureData
        // }))
    })

    // console.log(props.configureData)


    return (

        <Grow
            in={true}
            style={{ transformOrigin: '1 1 1' }}
        >
            <Box
                sx={{
                    position: "absolute",
                    bottom: "0",
                    margin: { xs: "0", sm: "20px" },
                    padding: '20px',
                    backgroundColor: "white",
                    borderRadius: "32px",
                    display: { xs: "none", sm: "flex" },
                    flexDirection: "column",
                    width: { xs: "calc(100% - 40px)", sm: "300px" },
                    zIndex: '100'
                }}
            >


                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: "space-between",

                        marginBottom: "10px"
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "800",
                            padding: "0 10px",
                            fontFamily: 'Mona Sans',
                            fontSize: "1.2rem",
                        }}
                    >Interior Colors</Typography>

                    <CloseIcon
                        onClick={handleClose}
                        sx={{
                            cursor: "pointer",
                            width: "20px",
                            height: "20px",
                            fontWeight: "200",
                            padding: "10px",
                            borderRadius: "50px",
                            backgroundColor: "#F5F5F5"
                        }}
                    ></CloseIcon>
                </Box>

                <SubColorPicker color={props.configureData.colors.ceiling} mode={mode} setMode={setModeHandler} type="ceiling" title="Ceiling" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                <SubColorPicker color={props.configureData.colors.seat} mode={mode} setMode={setModeHandler} type="seat" title="Seat" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                <SubColorPicker color={props.configureData.colors.wall} mode={mode} setMode={setModeHandler} type="wall" title="Wall Upper" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                <SubColorPicker color={props.configureData.colors.wallLower} mode={mode} setMode={setModeHandler} type="wallLower" title="Wall Lower" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />

                {/* <SubColorComp color={props.configureData.colors.wallLower} mode={mode} setMode={setModeHandler} type="wallLower" title="Wall Lower" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} /> */}
                <ColorPickerFloor color={props.configureData.colors.floor} mode={mode} setMode={setModeHandler} threejsModule={props.threejsModule}  type="floor" title="Floor" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />

            </Box>
        </Grow>
    )

}

export default InteriorComp






