import React, { useContext } from 'react';
import './styles/summaryStyles.css'; // Import the // Import the capitalizeFirstLetter function
import AIOContext from '../../AIOContext.js';
import formatCurrency from '../../util/formatCurrency.js';
import apiURL from '../../util/apiURL';
import jsPDF from 'jspdf';

function Confirmation({ activatePurchaseDapForm }) {
  const { user, dueAmount, dap, fleet, vehicle } = useContext(AIOContext);

  const { dapChecked, totalDapAmount } = dap;

  const { vehicleConfig } = vehicle;

  const { vehicleQuantities } = vehicleConfig;

  const { totalVehicles, totalVehicleTypesSelected, vehicleSubtotal } = fleet;

  const handleDownloadClick = (documentData) => {
    console.log('DOC DATA: ', documentData);
    fetch(`${apiURL}/api/reservations/specSheet`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'  // corrected typo here
        },
        body: JSON.stringify(documentData)
    })
    .then(response => response.text())
    .then(html => {
      console.log('IN FUNCTION')
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4'
      })
       
      const margins = {
        top: 10,
        bottom: 10,
        left: 10,
        width: 480
      }

      const { carType } = documentData
      // Use the html method with the specified margins
      doc.html(html, {
        x: margins.left,
        y: margins.top,
        width: margins.width,
        callback() {
            doc.save(`AIO ${carType.toUpperCase()} Specifications Sheet.pdf`);
        }
      })
    })
    .catch(e => console.error('HTML CANVAS === >', e));
  };

  const vehicleTypesSelected = Object.keys(vehicleQuantities).reduce(
    (types, vehicleType) => {
      if (vehicleQuantities[vehicleType] > 0) {
        types.push(vehicleType);
      }
      return types;
    },
    []
  );

  const handleDapDownload = () => {
    const filename = vehicleConfig.isFleet
      ? 'AIO FLEET'
      : `AIO ${vehicleConfig.carType.toUpperCase()}`;

    window.dispatchEvent(
      new CustomEvent('webxr-render-image', {
        detail: {
          vehicles: {
            car: vehicleConfig.isFleet
              ? vehicleTypesSelected.includes('car')
              : vehicleConfig.carType === 'car',
            truck: vehicleConfig.isFleet
              ? vehicleTypesSelected.includes('truck')
              : vehicleConfig.carType === 'truck',
            van: vehicleConfig.isFleet
              ? vehicleTypesSelected.includes('van')
              : vehicleConfig.carType === 'van',
          },
          filename,
        },
      })
    );
  };

  return (
    <React.Fragment>
      <div className='form-panel !pt-8'>
        <h2 className='font-wide text-3xl font-extrabold tracking-[0.03125rem] mb-8'>
          Reservation Confirmation
        </h2>

        <hr />

        <div className='pb-6 pt-10 flex flex-col gap-6'>
          <div className='font-wide text-2xl font-extrabold'>
            Hi, {user.userConfig.firstName}!
          </div>
          <div className='text-neutral-900 text-base font-normal leading-normal tracking-wide'>
            Welcome to the first-class experience on Earth
          </div>
        </div>

        <hr />

        {/* Purchase Overview */}
        <div className='pt-10 pb-6'>
          <div className='font-wide text-2xl font-extrabold pb-6'>
            Reservation Details
          </div>

          <div className='px-6 py-10 bg-neutral-100 rounded-lg border border-zinc-100 gap-8 flex flex-col text-neutral-900'>
            <div className=''>
              <div className='font-wide text-2xl font-extrabold uppercase pb-3'>
                {vehicleConfig.isFleet
                  ? 'AIO Fleet'
                  : 'AIO ' + vehicleConfig.carType}
                {'™'}
              </div>
              <div className='text-neutral-900 text-sm font-normal leading-normal tracking-wide uppercase'>
                The Single-Occupant Electric{' '}
                {vehicleConfig.isFleet ? 'Fleet' : vehicleConfig.carType}
              </div>
            </div>

            {vehicleConfig.isFleet && (
              <div className='flex flex-col gap-2'>
                <div className='flex justify-between'>
                  <div className='text-[1.125rem] font-wide font-bold'>
                    Total Vehicles
                  </div>
                  <div className='text-[0.875rem] tracking-[0.0625rem] font-semibold font-wide'>
                    Quantity
                  </div>
                </div>
                <div className='flex justify-between items-center gap-6'>
                  <div className='flex gap-2'>
                    {vehicleQuantities.car > 0 && (
                      <div className='chip'>
                        {vehicleQuantities.car}{' '}
                        {vehicleQuantities.car === 1 ? 'Car' : 'Cars'}
                      </div>
                    )}
                    {vehicleQuantities.truck > 0 && (
                      <div className='chip'>
                        {vehicleQuantities.truck}{' '}
                        {vehicleQuantities.truck === 1 ? 'Truck' : 'Trucks'}
                      </div>
                    )}
                    {vehicleQuantities.van > 0 && (
                      <div className='chip'>
                        {vehicleQuantities.van}{' '}
                        {vehicleQuantities.van === 1 ? 'Van' : 'Vans'}
                      </div>
                    )}
                  </div>
                  <div className='text-[1.125rem] font-wide font-bold'>
                    {totalVehicles}
                  </div>
                </div>
              </div>
            )}

            <hr />

            <div className='flex flex-col'>
              <div className='flex justify-between'>
                <div className=' flex items-center gap-2'>
                  <span className='text-lg font-bold font-wide'>
                    Reservation
                  </span>
                  {!vehicleConfig.isFleet && (
                    <div className='text-small'>(Refundable)</div>
                  )}
                </div>
                <div className='text-lg font-bold font-wide'>
                  {formatCurrency(vehicleSubtotal)} USD
                </div>
              </div>
              {vehicleConfig.isFleet && (
                <div className='flex justify-between text-[0.75rem] font-normal tracking-[0.0625rem]'>
                  <div className=''>(Refundable)</div>
                  <div className=''>
                    Vehicle Quantity ({totalVehicles}) x $100ea
                  </div>
                </div>
              )}
            </div>

            <div>
              <div className='flex justify-between gap-2  text-lg font-bold font-wide whitespace-nowrap'>
                <div className='text-left'>Digital Asset Package - DAP</div>

                {dapChecked ? (
                  <div>{formatCurrency(totalDapAmount)}</div>
                ) : (
                  <div className='text-right'>Not Purchased</div>
                )}
              </div>
              {vehicleConfig.isFleet && (
                <div className='text-right text-[0.75rem] font-normal tracking-[0.0625rem]'>
                  Vehicle Types ({totalVehicleTypesSelected}) x $10ea
                </div>
              )}

              {!dapChecked && (
                <div>
                  <div className='my-4 text-[0.75rem] font-normal tracking-[0.03125rem] leading-[1.125rem] text-[#383A3A]'>
                    <div>
                      AIO PICTURES™ artificially intelligent movie studio:
                    </div>
                    <ul className='my-0'>
                      <li>Ultra High Definition Images of AIO CUSTOM™</li>
                    </ul>
                  </div>

                  <button
                    className='bg-brand text-white py-3 px-[20px] rounded-[60px] uppercase tracking-[0.03125rem] leading-normal font-bold font-wide text-[0.75rem] cursor-pointer'
                    onClick={() => activatePurchaseDapForm()}
                  >
                    Purchase - {formatCurrency(totalDapAmount)} USD
                  </button>
                </div>
              )}
            </div>

            <hr />
            <div className=''>
              <div className='flex justify-between gap-2  text-xl font-extrabold font-wide'>
                <div className='text-left'>Total</div>

                <div className='text-right'>
                  {formatCurrency(dueAmount)} USD
                </div>
              </div>
              <div>
                <div className='text-right text-xs leading-normal tracking-wide'>
                  {/* date formatted like August 27, 2023 */}
                  Reservation made on{' '}
                  {new Date().toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        {dapChecked && (
          <div className='pt-4'>
            <hr />

            <div className='flex justify-between gap-2  text-2xl font-extrabold tracking-wide font-wide whitespace-nowrap pt-10'>
              <div className='text-left'>Digital Asset Package - DAP</div>
            </div>

            <div className='pb-10'>
              <div className='my-4 text-[0.75rem] font-normal tracking-[0.03125rem] leading-[1.125rem] text-[#383A3A]'>
                <div>AIO PICTURES™ artificially intelligent movie studio:</div>
                <ul className='my-0'>
                  <li>Ultra High Definition Images of AIO CUSTOM™</li>
                </ul>
              </div>

              <div className='flex justify-between items-center gap-2'>
                <button
                  className='bg-brand text-white py-3 px-[20px] rounded-[60px] uppercase tracking-[0.03125rem] leading-normal font-bold font-wide text-[0.75rem] cursor-pointer'
                  onClick={() => handleDapDownload()}
                >
                  Download Photos
                </button>
                <button className='bg-brand text-white py-3 px-[20px] rounded-[60px] uppercase tracking-[0.03125rem] leading-normal font-bold font-wide text-[0.75rem] cursor-pointer'>
                  Short Movie <i className='font-normal'>Coming Soon!</i>
                </button>
              </div>
            </div>

            <hr />
          </div>
        )}

        <div className='flex flex-col gap-6 pt-10 pb-8'>
          <div className='font-wide text-2xl font-extrabold'>
            Technical Specifications
          </div>
          <div className='text-neutral-900 text-sm font-normal leading-normal tracking-wide'>
            Vehicle details and custom information
          </div>
        </div>

        <div className='flex flex-col gap-4 pb-10'>
          {(vehicleConfig.isFleet
            ? vehicleTypesSelected.includes('car')
            : vehicleConfig.carType === 'car') && (
            <div className='flex justify-between items-center gap-6'>
              <div className='text-neutral-900 text-base font-bold tracking-wide'>
                AIO CAR™
              </div>
              <div>
                <button
                  className='bg-brand text-white py-3 px-[20px] rounded-[60px] uppercase tracking-[0.03125rem] leading-normal font-bold font-wide text-[0.75rem] cursor-pointer inline-flex items-center gap-2 no-underline'
                  onClick={() => handleDownloadClick({
                    vehicle: vehicle.vehicleConfig,
                    carType: 'car',
                    user: user.userConfig
                  })}
                >
                  Download Specs
                </button>
              </div>
            </div>
          )}

          {(vehicleConfig.isFleet
            ? vehicleTypesSelected.includes('truck')
            : vehicleConfig.carType === 'truck') && (
            <div className='flex justify-between items-center gap-6'>
              <div className='text-neutral-900 text-base font-bold tracking-wide'>
                AIO TRUCK™
              </div>
              <div>
                <button
                  className='bg-brand text-white py-3 px-[20px] rounded-[60px] uppercase tracking-[0.03125rem] leading-normal font-bold font-wide text-[0.75rem] cursor-pointer inline-flex items-center gap-2 no-underline'
                  onClick={() => handleDownloadClick({ 
                    vehicle: vehicle.vehicleConfig,
                    carType: 'truck',
                    user: user.userConfig
                  })}
                >
                  Download Specs
                </button>
              </div>
            </div>
          )}

          {(vehicleConfig.isFleet
            ? vehicleTypesSelected.includes('van')
            : vehicleConfig.carType === 'van') && (
            <div className='flex justify-between items-center gap-6'>
              <div className='text-neutral-900 text-base font-bold tracking-wide'>
                AIO VAN™
              </div>
              <div>
                <button
                  className='bg-brand text-white py-3 px-[20px] rounded-[60px] uppercase tracking-[0.03125rem] leading-normal font-bold font-wide text-[0.75rem] cursor-pointer inline-flex items-center gap-2 no-underline'
                  onClick={() => handleDownloadClick({ 
                    vehicle: vehicle.vehicleConfig,
                    carType: 'van',
                    user: user.userConfig
                  })}
                >
                  Download Specs
                </button>
              </div>
            </div>
          )}
        </div>

        <hr />

        <div className='terms pt-6 font-wide text-[12px] tracking-[0.03125rem]'>
          <a
            href='https://aiomotors.com/terms-of-use'
            target='_blank'
            rel='noreferrer'
            className='underline'
          >
            Terms of Use
          </a>
          {' | '}
          <a
            href='https://aiomotors.com/privacy-policy'
            target='_blank'
            rel='noreferrer'
            className='underline'
          >
            Privacy Policy
          </a>
          {' | '}
          <a
            href='https://aiomotors.com/reservation-agreement'
            target='_blank'
            rel='noreferrer'
            className='underline'
          >
            Reservation Agreement
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Confirmation;

function DownloadIcon() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25'
        stroke='#40A9B8'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 7.5L9 11.25L12.75 7.5'
        stroke='#40A9B8'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 11.25V2.25'
        stroke='#40A9B8'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
