import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Paper, Grow, styled, Menu, MenuItem, Typography } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';

import ColorSelector from "./ColorComp/ColorSelector";
import WheelComp from "./WheelComp/WheelComp";
import UploadLogoComp from "./UploadLogoComp/UploadLogoComp";
import WheelCompMobile from "./WheelComp/WheelCompMobile";

import ColorSelectorMobile from "./ColorComp/ColorSelectorMobile";


import IconButtonCompMobile from "./Utility/IconButtonCompMobile";

import InteriorComp from "./InteriorComp";
import InteriorCompMobile from "./InteriorCompMobile";

const CustomButton = styled(Button)((props) => ({
    height: "40px",
    color: props.active ? "white" : "black",
    fontFamily: 'Mona Sans',
    fontWeight: "800",
    fontSize: "16px",
    borderRadius: "100px",
    backgroundColor: props.active ? "black" : "#F1F1F1",
    padding: "24px",
    textTransform: "capitalize",
    '&:hover': {
        backgroundColor: props.active ? "black" : "#F1F1F1",
    },
}));

let canMove = false

function ConfigureCompMobile(props) {
    const [exteriorMode, setExteriorMode] = useState(true)
    const [open, setOpen] = useState(true)
    const [paddingB, setPaddingB] = useState(window.innerHeight - 100)


    // const [configureMode, setConfigureMode] = useState(null)
    // const [configureMode, setConfigureMode] = useState(null)
    const [configureOpen, setConfigureOpen] = useState(false)


    const configureMode = props.configureMode
    const setConfigureMode = props.setConfigureMode



    // const [checked, setChecked] = useState(false)

    const [headLight, setHeadLight] = useState(false)

    const anchorRef = useRef()

    // configureData={props.configureData}
    // console.log(props.configureData)

    const handleClose = () => {

        console.log({ paddingB })
        if (paddingB < window.innerHeight - 200) {
            setPaddingB(window.innerHeight - 100)
        } else {
            setPaddingB(window.innerHeight - 270)

        }

    }

    const showUIHanlder = (value) => {

        // setConfigureMode(value)

        props.setConfigureMode(value)

        console.log(value)

    }
    const pointerMoveHandler = (e) => {
        if (canMove) {
            const deltaY = e.targetTouches[0].clientY
            const value = clamp(deltaY, window.innerHeight - 300, window.innerHeight - 100)
            setPaddingB(value)
            // console.log(value)
            // console.log(e)
        }
    }

    const pointerStartHandler = (e) => {
        canMove = true
    }
    const pointerEndHandler = () => {
        canMove = false

        if (paddingB < window.innerHeight - 150) {
            setPaddingB(window.innerHeight - 270)
        } else {
            setPaddingB(window.innerHeight - 100)
        }
    }

    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);




    const switchInteriorHandler = (value) => {

        props.threejsModule.swichInteroir(value)
        // console.log(props.isInterior)
        props.setIsInterior(value)

        // if (props.isInterior) {
        //     props.setIsInterior(true)
        //     // window.dispatchEvent(new CustomEvent('change-configurate-mode', { detail: { mode: "enterior" } }));
        // } else {
        //     props.setIsInterior(false)
        //     // window.dispatchEvent(new CustomEvent('change-configurate-mode', { detail: { mode: null } }));
        // }

    }


    return (
        <React.Fragment>
            <Box
                onTouchStart={pointerStartHandler}
                onTouchEnd={pointerEndHandler}
                onClick={handleClose}
                onTouchMove={pointerMoveHandler}
                sx={{
                    backgroundColor: "white",
                    width: "100%",
                    height: "240px",
                    position: "absolute",
                    zIndex: "100",
                    display: { xs: "flex", sm: "none" },
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRadius: "24px",
                    paddingBottom: "60px",
                    gap: "24px",
                    transitionProperty: "top, transform",
                    transitionDuration: " 0.3s",
                    top: `${paddingB}px`,
                    transform: `${props.expand ? "translate(0, 400px)" : "none"}`
                }}
            >

                <Box
                    // onTouchStart={handleClose}
                    // onTouchStart={pointerStartHandler}
                    // onTouchEnd={pointerEndHandler}
                    // onClick={handleClose}

                    sx={{
                        width: "100%",
                        height: "30px",
                        display: "flex",
                        marginTop: "5px",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "30px",
                            height: "4px",
                            backgroundColor: "#C6C6C6",
                            borderRadius: "30px",
                        }}
                    >
                    </Box>
                </Box>

                <Typography
                    sx={{
                        marginTop: "-10px",
                        textAlign: "center",
                        fontDamily: " Mona-Sans",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                        letterSpacing: "-0.5px",
                    }}
                >
                    Customize Vehicle
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        gap: "10px"
                    }}
                >
                    {
                        !props.isInterior &&
                        <IconButtonCompMobile
                            onClick={() => { showUIHanlder("color") }}
                            component="./assets/images/icons/ColorPaletteSample1.svg"
                        >
                        </IconButtonCompMobile>
                    }
                    {
                        props.isInterior &&
                        <IconButtonCompMobile
                            onClick={() => { showUIHanlder("interior") }}
                            component="./assets/images/icons/ColorPaletteSample1.svg"
                        >
                        </IconButtonCompMobile>
                    }
                    {!props.isInterior &&
                        <IconButtonCompMobile
                            onClick={() => { showUIHanlder("wheel") }}
                            component="./assets/images/icons/Car-Tool-Steering-Wheel.svg"
                        >
                        </IconButtonCompMobile>
                    }
                    {
                        props.configureData.carType === "van" && !props.isInterior &&
                        <IconButtonCompMobile
                            onClick={() => { showUIHanlder("logo") }}
                            component="./assets/images/icons/AddImage.svg"
                            >
                        </IconButtonCompMobile>
                    }

                </Box>


                <Box
                    sx={{
                        display: "flex",
                        gap: "20px"
                    }}
                >
                    <CustomButton
                        active={props.isInterior === false}
                        onClick={() => { switchInteriorHandler(false) }}
                    >
                        Exterior
                    </CustomButton>

                    <CustomButton
                        active={props.isInterior === true}
                        onClick={() => { switchInteriorHandler(true) }}
                    >
                        Interior
                    </CustomButton>

                </Box>

            </Box>

            {/* {!configureMode && <ConfigureComp configureOpen={configureOpen} setConfigureOpen={setConfigureOpen} threejsModule={props.threejsModule} setConfigureMode={setConfigureMode} />} */}
            {configureMode === "color" && <ColorSelectorMobile setPaddingB={setPaddingB} configureData={props.configureData} threejsModule={props.threejsModule} setConfigureMode={setConfigureMode} />}
            {configureMode === "wheel" && <WheelCompMobile setPaddingB={setPaddingB} configureData={props.configureData} threejsModule={props.threejsModule} setConfigureMode={setConfigureMode} />}
            {configureMode === "interior" && <InteriorCompMobile setPaddingB={setPaddingB} configureData={props.configureData} threejsModule={props.threejsModule} setConfigureMode={setConfigureMode} />}

        </React.Fragment >
    )
}

export default ConfigureCompMobile