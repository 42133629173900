import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { Box, Grow, styled, Avatar, Typography, Collapse } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SubColorComp from './Comp/SubColorComp';
import SubMaterial from './Comp/SubMaterial';


import CloseComp from '../Utility/CloseComp';
function ColorSelector(props) {

    const [color, setColor] = useState()

    const [mode, setMode] = useState(null)

    const [materialMode, setMaterialMode] = useState(false)

    const handleChangeComplete = (color) => {
        console.log(color)
        setColor(color.hex)
        props.threejsModule.updateColor(color)
    }

    const setModeHandler = (value) => {

        if (value === mode) {
            setMode(null)
        } else {
            props.threejsModule.activeMaterial(value)
            setMode(value)
        }

    }


    const handleClose = () => {

        props.setConfigureMode(null)
    }

    // console.log(props.configureData)


    return (

        <Grow
            in={true}
            style={{ transformOrigin: '1 1 1' }}
        >
            <Box
                sx={{
                    position: "absolute",
                    bottom: "0",
                    margin: { xs: "0", sm: "20px" },
                    padding: '20px',
                    backgroundColor: "white",
                    borderRadius: "32px",
                    display: { xs: "none", sm: 'flex' },
                    flexDirection: "column",
                    width: { xs: "calc(100% - 40px)", sm: "300px" },
                    zIndex: '100'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: "space-between",

                        marginBottom: "10px"
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "700",
                            padding: "0 10px",
                            fontFamily: 'Mona Sans',
                            fontSize: "20px",
                        }}
                    >Exterior Colors</Typography>

                    <CloseComp
                        onClick={handleClose}
                    ></CloseComp>
                </Box>


                {!materialMode &&
                    <SubColorComp color={props.configureData.colors.body} mode={mode} setMode={setModeHandler} type="body" title="Body" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                }

                {!materialMode &&
                    <SubColorComp color={props.configureData.colors.front} mode={mode} setMode={setModeHandler} type="front" title="Front Bumper" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                }

                {!materialMode &&
                    <SubColorComp color={props.configureData.colors.rear} mode={mode} setMode={setModeHandler} type="rear" title="Rear Bumper" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                }
                {!materialMode &&
                    <SubColorComp color={props.configureData.colors.roof} mode={mode} setMode={setModeHandler} type="roof" title="Roof" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                }
                {!materialMode &&
                    <SubColorComp color={props.configureData.colors.rocket} mode={mode} setMode={setModeHandler} type="rocket" title="Rocker" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                }
                {/* {!materialMode &&
                    <SubColorComp color={props.configureData.colors.window} mode={mode} setMode={setModeHandler} type="window" title="Window Tint" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                } */}
                {!materialMode &&
                    <SubColorComp color={props.configureData.colors.trim} mode={mode} setMode={setModeHandler} type="trim" title="Window Trim" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                }
                {!materialMode &&
                    <SubColorComp color={props.configureData.colors.grille} mode={mode} setMode={setModeHandler} type="grille" title="Grille" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                }

                {materialMode &&
                    <SubMaterial configureData={props.configureData} mode={mode} setMode={setMaterialMode} threejsModule={props.threejsModule} />
                }

            </Box>
        </Grow>
    )

}

export default ColorSelector




