import React, { useState } from 'react';
import { Box, Grow, CircularProgress, Avatar, Typography, Collapse } from "@mui/material";
import { keyframes } from '@mui/system';




const spin = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;


function GeneratingComp(props) {

    return (
        <Box
            sx={{
                cursor: "grab",
                width: "100%",
                height: "100%",
                backgroundColor: "#090b12d1",
                position: "absolute",
                top: "0",
                zIndex: "1000",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Grow
                in={true}
            >
                <Box
                    sx={{
                        backgroundColor: 'white',
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "16px",
                        gap: "20px",
                        padding: "32px 64px",
                    }}
                >


                    <Box
                        component="img"
                        src="./assets/images/icons/LoadingCircle.svg"
                        sx={{
                            animation: `${spin} 0.6s linear infinite`,
                            width: "32px"
                        }}
                    >
                    </Box>

                    <Typography
                        sx={{
                            fontFamily: 'Mona Sans',
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "1px",
                            maxWidth:"400px",
                        }}
                    >
                        Generating high-resolution photos, please wait...
                        This can take anywhere between 1–2 minutes.
                    </Typography>
                </Box>
            </Grow>
        </Box>

    )

}

export default GeneratingComp

