import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { Box, Grow, styled, Avatar, Typography, Collapse, Grid, CardMedia } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SubColorComp from './ColorComp/Comp/SubColorComp';
import SubMaterial from './ColorComp/Comp/SubMaterial';
import SubColorCompMobile from './ColorComp/Comp/SubColorCompMobile';
import BackButton from './Utility/BackButton';

import SubColorPicker from './ColorComp/Comp/SubColorPicker';
import ColorPickerFloor from './ColorComp/Comp/ColorPickerFloor';

import SubColorPickerSelectMobile from './ColorComp/Comp/SubColorPickerSelectMobile';

import SubMaterialMobile from './ColorComp/Comp/SubMaterialMobile';


function InteriorCompMobile(props) {

    const [color, setColor] = useState()
    const [mode, setMode] = useState(null)
    const [materialMode, setMaterialMode] = useState(false)

    const [panelMode, setPanelMode] = useState(null)

    const [floorMaterial, setMaterialOpen] = useState(false)

    const handleChangeComplete = (color) => {
        setColor(color.hex)
        props.threejsModule.updateColor(color)
    }

    const setModeHandler = (value, title) => {

        console.log(value, title)
        setPanelMode(title)
        setMode(value)
        props.threejsModule.activeMaterial(value)

    }

    const handleClose = () => {
        props.setConfigureMode(null)
    }

    const backHandler = () => {
        props.setConfigureMode(null)
        props.setPaddingB(window.innerHeight - 270)
    }

    const setMaterialModeHandler = () => {
        setPanelMode("material")
    }


    const onSelectHandler = (e) => {
        console.log(e)
        console.log({ mode })
        const data = {
            material: mode,
            color: e.color
        }
        props.threejsModule.updateColor(data)
    }

    // console.log(props.configureData)

    return (
        <React.Fragment>
            {!panelMode && !floorMaterial
                &&
                <Grow
                    in={true}
                    style={{ transformOrigin: '1 1 1' }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: "0",
                            margin: { xs: "0", sm: "20px" },
                            padding: '20px',
                            backgroundColor: "white",
                            borderRadius: "32px",
                            display: { xs: "flex", sm: "none" },
                            flexDirection: "column",
                            width: { xs: "calc(100% - 40px)", sm: "300px" },
                            zIndex: '100'
                        }}
                    >


                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: "space-between",

                                marginBottom: "10px"
                            }}
                        >
                            <BackButton onClick={backHandler} />
                            <Typography
                                sx={{
                                    fontWeight: "700",
                                    fontFamily: 'Mona Sans',
                                    fontSize: "24px",
                                    alignSelf: "center",
                                    marginRight: "auto",
                                    marginLeft: "10px"
                                }}
                            >Interior Colors</Typography>




                            <CloseIcon
                                onClick={handleClose}
                                sx={{
                                    cursor: "pointer",
                                    width: "20px",
                                    height: "20px",
                                    fontWeight: "200",
                                    padding: "10px",
                                    borderRadius: "50px",
                                    backgroundColor: "#F5F5F5"
                                }}
                            ></CloseIcon>
                        </Box>
                        <SubColorComp color={props.configureData.colors.ceiling} setMode={setModeHandler} type="ceiling" title="Ceiling" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                        <SubColorComp color={props.configureData.colors.seat} setMode={setModeHandler} type="seat" title="Seat" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                        <SubColorComp color={props.configureData.colors.wall} setMode={setModeHandler} type="wall" title="Wall Upper" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                        <SubColorComp color={props.configureData.colors.wallLower} setMode={setModeHandler} type="wallLower" title="Wall Lower" />
                        <ColorPickerFloor color={props.configureData.colors.floor} setMode={() => {
                            setMaterialOpen(true)
                        }} type="floor" title="Floor" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />

                    </Box>
                </Grow>
            }
            {/* {panelMode === "color" &&
                <SubColorCompMobile setConfigureMode={props.setConfigureMode} mode={mode} configureData={props.configureData} setMode={() => { setPanelMode(null) }} type="body" title="Body" setMaterialMode={setMaterialModeHandler} onChangeColor={handleChangeComplete} />
            } */}

            {panelMode && panelMode != "material" &&
                <SubColorPickerSelectMobile color={props.configureData.colors[mode].color} colors={props.colors} onSelect={onSelectHandler} onBack={() => { setPanelMode(false) }} onClose={handleClose}  />
            }


            {floorMaterial &&
                <SubMaterialMobile setMaterialOpen={() => { }} configureData={props.configureData} threejsModule={props.threejsModule} onBack={() => { setMaterialOpen(false) }} onClose={handleClose} />
            }

        </React.Fragment>
    )

}

export default InteriorCompMobile

