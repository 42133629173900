import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box, Button, IconButton, CircularProgress } from '@mui/material';

import UIcomponent from './components/UIcomponents';
import ThreejsModule from './components/Modules/threejsModule';
import LoadingTextureComp from './components/ConfigurationComp/LoadingComps/LoadingTextureComp';
import InitLoadingComp from './components/ConfigurationComp/LoadingComps/InitLoadingComp';
import PortraitComp from './components/ConfigurationComp/LoadingComps/PortraitComp';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import AIOProvider from './AIOProvider';
import GeneratingComp from './components/ConfigurationComp/LoadingComps/GeneratingComp';
import ReactGA from "react-ga4";

let threejsModule;
const queryClient = new QueryClient();


ReactGA.initialize("G-MXY3RC1CVS");


function App(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('fleet');

  const isFleet = props.configureData.fleet || myParam;

  const canvasRef = useRef();
  const [loading, setLoading] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const loadingRef = useRef();
  const [loadingTexture, setLoadingTexture] = useState(true);
  const [isInterior, setIsInterior] = useState(false);

  const [generating, setGenerating] = useState('done');

  const [generatingTotal, setGeneratingTotal] = useState(0);

  // console.log({ isFleet, myParam });
  // console.log(props.configureData);
  const [configureMode, setConfigureMode] = useState(null);

  const setLoadHandler = () => {
    loadingRef.current.style.opacity = 0;
  };

  const addGenerating = (input) => {
    if (input === 1) {
      setGenerating(generating + 1);
    } else {
      setGenerating(input);
    }
  };

  const exportImages = () => {
    // props.threejsModule.renderPreview()

    // window.dispatchEvent

    // webxr-render-feature-image

    window.dispatchEvent(new CustomEvent('webxr-render-feature-image', {}));

    window.dispatchEvent(
      new CustomEvent('webxr-render-image', {
        detail: {
          car: true,
          truck: true,
          van: true,
        },
      })
    );

    // webxr-render-image
  };

  const videoEndHandler = (e) => {
    threejsModule.onVideoEnd();
  };

  // console.log(props.configureData)
  useEffect(() => {
    const newData = props.configureData;

    if (isFleet) {
      newData.carType = 'van';
    }

    threejsModule = new ThreejsModule({
      canvasRef,
      setLoading,
      configureData: newData,
      setConfigureData: props.setConfigureData,
      setLoadingTexture,
      setLoadHandler,
      setIsInterior,
      setConfigureMode,
      isFleet: props.configureData.isFleet,
      addGenerating,
      setGeneratingTotal,
    });
    threejsModule.init();
  }, []);

  return (
    <AIOProvider>
      <React.Fragment>
        <QueryClientProvider client={queryClient}>
          <Box
            ref={canvasRef}
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: '#f4f4f4',
              position: 'relative',
            }}
          ></Box>

          <Button
            onClick={() => {
              setGenerating(true);
              setTimeout(() => {
                exportImages(true);
              }, 100);
            }}
            sx={{
              position: 'absolute',
              width: '120px',
              top: '20px',
              zIndex: '500',
              left: 'calc(50% - 60px)',
              fontFamily: 'Mona Sans',
              backgroundColor: 'white',
              fontWeight: { xs: '800', sm: '600' },
              display: 'none',
            }}
          >
            Download
          </Button>

          {generating != 'done' && (
            <GeneratingComp current={generating} total={generatingTotal} />
          )}

          {/* <GeneratingComp /> */}

          <UIcomponent
            configureMode={configureMode}
            setConfigureMode={setConfigureMode}
            reservationData={props.reservationData}
            isFleet={props.configureData.isFleet}
            isInterior={isInterior}
            setIsInterior={setIsInterior}
            setLoaded={setLoaded}
            configureData={props.configureData}
            threejsModule={threejsModule}
            setGenerating
          />
          {!loaded && (
            <Box
              onTransitionEnd={() => {
                setLoaded(true);
              }}
              ref={loadingRef}
              sx={{
                width: '100%',
                height: '100%',
                backgroundColor: '#f4f4f4',
                position: 'absolute',
                top: '0',
                zIndex: '1000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '20px',
                transition: 'opacity 1s ease-out',
              }}
            >
              <InitLoadingComp loading={loading}></InitLoadingComp>
            </Box>
          )}

          {loadingTexture && <LoadingTextureComp />}

          <video
            onEnded={videoEndHandler}
            id='screen-video'
            muted
            playsInline
            style={{
              position: 'absolute',
              top: '0',
              width: '1px',
              opacity: '0',
              zIndex: '-1000',
            }}
            src=' '
          ></video>
        </QueryClientProvider>
      </React.Fragment>
    </AIOProvider>
  );
}

export default App;
