import App from './App';
import { useEffect, useState } from 'react';

import { downloadData } from './components/Modules/DataInputUpload';
import apiURL from './util/apiURL.js';

function LoadData() {
  const defaultData = {
    colors: {
      body: {
        color: '#B8B8B8',
        material: 'Matt',
      },
      front: {
        color: '#2D2D2D',
        material: 'Matt',
      },
      rear: {
        color: '#2D2D2D',
        material: 'Matt',
      },
      roof: {
        color: '#2D2D2D',
        material: 'Matt',
      },
      rocket: {
        color: '#2D2D2D',
        material: 'Matt',
      },
      window: {
        color: '#BCBCBC',
        material: 'Matt',
      },
      trim: {
        color: '#2D2D2D',
        material: 'Matt',
      },
      grille: {
        color: '#2D2D2D',
        material: 'Matt',
      },
      cover: {
        color: '#2D2D2D',
        material: 'Matt',
      },
      rim: {
        color: '#D9D9D9',
        material: 'Matt',
      },
    },
    logoUrl: './assets/images/AIOLogo.png',
    logoScale: 1,
    logoPosition: [0, 0, 0],
    aeroCover: true,
    carType: 'car',
    isFleet: false,
    vehicleQuantities: {
      car: 1,
      truck: 1,
      van: 1,
    },
  };

  const [configureData, setConfigureData] = useState();

  const [reservationData, setReservationData] = useState();

  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('fleet');
  const vehicleParam = urlParams.get('vehicle');
  const reservationParam = urlParams.get('reservation');

  // const uuid = window.location.pathname.split('/')[1];
  // uuid && console.log('UUID: ', uuid);

  console.log(process.env.NODE_ENV);

  async function loadReservationData(reservationParam) {
    const response = await fetch(
      `${apiURL}/api/reservations/${reservationParam}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const body = await response.json();
    console.log(body);
    if (response.status !== 200) {
      throw Error(body.message);
    }
    return body;
  }

  const load = async () => {
    try {
      const response = await fetch(`./appData2.json`);
      console.log(response);
    } catch (error) {
      // TypeError: Failed to fetch
      console.log('There was an error', error);
    }
  };

  const loadData = async () => {
    if (reservationParam) {
      const reservationData = await loadReservationData(reservationParam);

      console.log(reservationData);
      setConfigureData(reservationData.body.vehicle);
      // update the configuredata with the logo_url
      setConfigureData((prevState) => ({
        ...prevState,
        logoUrl: reservationData.body.logo_url,
        logoScale: reservationData.body.vehicle.logoScale,
      }));

      setReservationData({
        firstName: reservationData.body.first_name,
        lastName: reservationData.body.last_name,
        companyName: reservationData.body.company_name,
        emailAddress: reservationData.body.email_address,
        dapPurchased:
          reservationData.body.dap === null ? false : reservationData.body.dap,
        createdAt: reservationData.body.created_at,

        cardBrand: reservationData.body.card_brand,
        lastFour: reservationData.body.card_last_four,
        cardFunding: reservationData.body.card_funding,

        customerId: reservationData.body.stripe_customer_id,
        uuid: reservationData.body.aio_verse_url,
      });
    } else {
      const data = await downloadData();

      if (data) {
        const isFleet = data.fleet || myParam;

        data.carType = vehicleParam || data.carType;

        if (isFleet) {
          data.carType = 'van';
          data.isFleet = true;
        }

        setConfigureData(data);
        console.log(data);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    configureData && (
      <App
        configureData={configureData}
        setConfigureData={setConfigureData}
        reservationData={reservationData}
      />
    )
  );
}

export default LoadData;
