import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { Box, Grow, styled, Avatar, Typography, Collapse } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import SubColorComp from './Comp/SubColorComp';
import SubMaterial from './Comp/SubMaterial';
import SubColorCompMobile from './Comp/SubColorCompMobile';
import BackButton from '../Utility/BackButton';


import CloseComp from '../Utility/CloseComp';
function ColorSelectorMobile(props) {

    const [color, setColor] = useState()

    const [mode, setMode] = useState(null)

    const [materialMode, setMaterialMode] = useState(false)

    const [panelMode, setPanelMode] = useState(null)

    const handleChangeComplete = (color) => {
        setColor(color.hex)
        props.threejsModule.updateColor(color)
    }



    const setModeHandler = (value) => {

        console.log(value)
        setPanelMode("color")
        // if (value === mode) {
        //     setMode(null)
        // } else {
        //     props.threejsModule.activeMaterial(value)
        //     setMode(value)
        // }

        setMode(value)


        props.threejsModule.activeMaterial(value)

    }


    const handleClose = () => {

        props.setConfigureMode(null)
    }

    const backHandler = () => {
        props.setConfigureMode(null)
        props.setPaddingB(window.innerHeight - 270)
    }


    const setMaterialModeHandler = () => {

        setPanelMode("material")
    }

    // console.log(props.configureData)


    return (
        <React.Fragment>

            {!panelMode

                &&
                <Grow
                    in={true}
                    style={{ transformOrigin: '1 1 1' }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: "0",
                            margin: { xs: "0", sm: "20px" },
                            padding: '20px',
                            backgroundColor: "white",
                            borderRadius: "32px",
                            display: { xs: "flex", sm: 'none' },
                            flexDirection: "column",
                            width: { xs: "calc(100% - 40px)", sm: "300px" },
                            zIndex: '100'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: "space-between",

                                marginBottom: "10px"
                            }}
                        >
                            <BackButton onClick={backHandler} />
                            <Typography
                                sx={{
                                    fontWeight: "700",
                                    fontFamily: 'Mona Sans',
                                    fontSize: "24px",
                                    alignSelf: "center",
                                    marginRight: "auto",
                                    marginLeft: "10px"
                                }}
                            >Exterior Colors</Typography>
                            <CloseComp
                                onClick={handleClose}
                            ></CloseComp>
                        </Box>


                        <SubColorComp color={props.configureData.colors.body} setMode={setModeHandler} type="body" title="Body" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                        <SubColorComp color={props.configureData.colors.front} setMode={setModeHandler} type="front" title="Front Bumper" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                        <SubColorComp color={props.configureData.colors.rear} setMode={setModeHandler} type="rear" title="Rear Bumper" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                        <SubColorComp color={props.configureData.colors.roof} setMode={setModeHandler} type="roof" title="Roof" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                        <SubColorComp color={props.configureData.colors.rocket} setMode={setModeHandler} type="rocket" title="Rocker" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                        <SubColorComp color={props.configureData.colors.trim} setMode={setModeHandler} type="trim" title="Window Trim" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />
                        <SubColorComp color={props.configureData.colors.grille} setMode={setModeHandler} type="grille" title="Grille" setMaterialMode={setMaterialMode} onChangeColor={handleChangeComplete} />


                    </Box>
                </Grow>

            }
            {panelMode === "color" &&
                <SubColorCompMobile setConfigureMode={props.setConfigureMode} mode={mode} configureData={props.configureData} setMode={() => { setPanelMode(null) }} type="body" title="Body" setMaterialMode={setMaterialModeHandler} onChangeColor={handleChangeComplete} />
            }
            {panelMode === "material" &&
                <SubMaterial configureData={props.configureData} mode={mode} setMode={() => { setPanelMode("color") }} threejsModule={props.threejsModule} />
            }
        </React.Fragment>

    )

}

export default ColorSelectorMobile


const ColorComp = (props) => {

    console.log(props.configureData)

    const color = props.configureData.colors[props.mode].color

    const matt = props.configureData.colors[props.mode].material


    const colorDataSet = {
        body: "Body",
        front: "Front Bumper",
        rear: "Rear Bumper",
        roof: "Roof",
        rocket: "Rocker",
        trim: "Window Trim",
        grille: "Grille",
        cover: "Body",
        rim: "Body",
        ceiling: "Ceiling",
        screen: "Horizon Screen",
        seat: "Seat",
        wall: "Wall Panel Lower",
        floor: "Floor",
    }


    const colorUpdateHandler = (e) => {
        // console.log(e.target.value)
        // setColor(e.target.value);

        console.log(props.configureData)

        // const color = props.configureData[]

        // setColor(e.target.value)

        // console.log(e.target.value)

        const input = e.target.value

        if (input.length === 7) {
            const data = {
                material: props.mode,
                color: e.target.value
            }
            props.onChangeColor(data)

        } else if (input.length === 6) {
            const data = {
                material: props.mode,
                color: `#${e.target.value}`
            }
            props.onChangeColor(data)
        }
    }

    const setMaterialHandler = (value) => {

        console.log(value)

        props.setMaterialMode(value)
    }


    const handleClose = () => {
        props.setMode(null)
    }

    return (

        <Grow
            in={true}
            style={{ transformOrigin: '1 1 1' }}
        >
            <Box
                sx={{
                    position: "absolute",
                    bottom: "0",
                    width: "calc(100% - 40px)",
                    margin: "0px",
                    padding: '20px',
                    backgroundColor: "white",
                    borderRadius: "32px",
                    display: 'flex',
                    gap: '5px',
                    flexDirection: "column",
                    zIndex: "1000",
                    left: "0",
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: "space-between",
                        padding: "0 10px",
                        marginBottom: "10px"
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "800",
                            fontFamily: 'Mona Sans',
                            fontSize: "1.2rem",
                        }}
                    >{colorDataSet[props.mode]}</Typography>

                    <CloseComp

                        onClick={handleClose}
                    ></CloseComp>
                </Box>

                <Box
                    sx={{
                        backgroundColor: "#383A3A",
                        borderRadius: "16px",
                        padding: "12px 12px",

                    }}
                >

                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                            marginTop: "15px",

                        }}
                    >
                        <Box
                            sx={{
                                color: "white",
                                marginRight: "auto",
                                fontFamily: 'Mona Sans',
                                fontSize: "0.8rem",
                                fontWeight: "600",
                            }}
                        >
                            Color
                        </Box>

                        <Box
                            sx={{
                                color: "#7F7F7F",
                                fontSize: "0.7rem",
                                display: "flex",
                                alignItems: "center",
                                border: "2px solid #777777",

                            }}
                        >
                            <Typography
                                sx={{
                                    color: "white",
                                    padding: "1px 6px",
                                    fontWeight: "600",
                                    fontSize: "0.8rem",
                                    fontFamily: 'Mona Sans',
                                    backgroundColor: "#777777",

                                }}
                            >
                                HEX
                            </Typography>
                            <Box
                                onInput={colorUpdateHandler}
                                component="input"
                                value={color[0] === "#" ? color.substring(1) : color}
                                sx={{
                                    width: "70px",
                                    color: "white",
                                    fontWeight: "500",
                                    textAlign: 'center',
                                    borderRadius: "0",
                                    fontWeight: "600",
                                    border: "0px",
                                    boxShadow: "none",
                                    cursor: 'pointer',
                                    backgroundColor: "#ffffff00",
                                    textTransform: "uppercase",
                                    '&:focus': {
                                        border: "0px",

                                    }
                                }}
                            ></Box>
                        </Box>
                        <Box
                            sx={{
                                border: "0px solid #777777",
                                padding: "0",
                                backgroundColor: color.length === 7 ? color : `#${color}`,
                                width: "60px",
                                height: "24px",
                                borderRadius: "4px",
                                cursor: 'pointer',
                            }}
                        >
                            <input style={{
                                opacity: "0",
                                cursor: 'pointer',
                            }} onChange={colorUpdateHandler} type="color" value={props.color} />

                        </Box>
                    </Box>

                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            marginTop: "15px",
                        }}
                    >
                        <Box
                            sx={{
                                color: "white",
                                marginRight: "auto",
                                fontFamily: 'Mona Sans',
                                fontSize: "0.8rem",
                                fontWeight: "600",
                            }}
                        >
                            Material
                        </Box>
                        <Box
                            sx={{
                                color: "white",
                                fontSize: "0.8rem",
                                textTransform: "uppercase",
                                fontFamily: 'Mona Sans',
                                fontWeight: "600",

                            }}
                        >
                            {matt}
                        </Box>
                        <Box
                            onClick={() => { setMaterialHandler(props.mode) }}
                            sx={{
                                width: "60px",
                                height: "24px",
                                backgroundColor: "black",
                                padding: "0",
                                cursor: 'pointer',
                                borderRadius: "4px",
                            }}
                        >

                        </Box>
                    </Box>
                </Box>
            </Box >
        </Grow>

    )

}






