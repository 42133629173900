import React from 'react';
import './styles/paymentStyles.css'; // Import the payment styles
import { PaymentElement } from '@stripe/react-stripe-js';

function UpdatePaymentMethod() {
  return (
    <div className='form-panel flex flex-col'>
      <h2 className='font-wide text-3xl font-extrabold tracking-[0.03125rem] mb-14'>
        Edit Payment Method
      </h2>
      <div className='pb-6'>
        <div className='text-sm font-normal'>
          Enter the payment method you would like to use
        </div>
      </div>
      <form>
        <PaymentElement />
      </form>
      <div className='flex items-center gap-2 mt-8'>
        <div>
          <svg
            width='28'
            height='28'
            viewBox='0 0 28 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width='28' height='28' rx='14' fill='#38B8AB' />
            <path
              d='M18.3737 12.5417H17.9362V10.9375C17.9362 9.89321 17.5214 8.89169 16.7829 8.15327C16.0445 7.41484 15.043 7 13.9987 7C12.9544 7 11.9529 7.41484 11.2145 8.15327C10.476 8.89169 10.0612 9.89321 10.0612 10.9375V12.5417H9.6237C9.31428 12.5417 9.01753 12.6646 8.79874 12.8834C8.57995 13.1022 8.45703 13.3989 8.45703 13.7083V19.8333C8.45703 20.1427 8.57995 20.4395 8.79874 20.6583C9.01753 20.8771 9.31428 21 9.6237 21H18.3737C18.6831 21 18.9799 20.8771 19.1987 20.6583C19.4174 20.4395 19.5404 20.1427 19.5404 19.8333V13.7083C19.5404 13.3989 19.4174 13.1022 19.1987 12.8834C18.9799 12.6646 18.6831 12.5417 18.3737 12.5417ZM13.9987 17.7917C13.768 17.7917 13.5424 17.7232 13.3505 17.595C13.1587 17.4669 13.0091 17.2846 12.9208 17.0715C12.8325 16.8583 12.8094 16.6237 12.8544 16.3974C12.8995 16.1711 13.0106 15.9632 13.1737 15.8C13.3369 15.6369 13.5448 15.5258 13.7711 15.4807C13.9974 15.4357 14.232 15.4588 14.4452 15.5471C14.6583 15.6354 14.8405 15.785 14.9687 15.9768C15.0969 16.1687 15.1654 16.3943 15.1654 16.625C15.1654 16.9344 15.0424 17.2312 14.8237 17.45C14.6049 17.6687 14.3081 17.7917 13.9987 17.7917ZM16.4779 12.25C16.4779 12.3274 16.4471 12.4015 16.3924 12.4562C16.3377 12.5109 16.2636 12.5417 16.1862 12.5417H11.8112C11.7338 12.5417 11.6597 12.5109 11.605 12.4562C11.5503 12.4015 11.5195 12.3274 11.5195 12.25V10.9375C11.5195 10.28 11.7807 9.6494 12.2457 9.18446C12.7106 8.71953 13.3412 8.45833 13.9987 8.45833C14.6562 8.45833 15.2868 8.71953 15.7517 9.18446C16.2167 9.6494 16.4779 10.28 16.4779 10.9375V12.25Z'
              fill='white'
            />
          </svg>
        </div>

        <div className='text-small font-wide'>
          Your card details are secured and encrypted by Stripe.
        </div>
      </div>
    </div>
  );
}

export default UpdatePaymentMethod;
