import React, { useRef, useState } from "react";
import { Box, alpha, styled, Switch, Typography, Collapse, IconButton, Divider, TextField } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';


const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#000000",
        '&:hover': {
            backgroundColor: alpha("#000000", theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#000000",
    },
}));

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : `${theme.color}`,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const AeroComp = (props) => {

    const [color, setColor] = useState(props.color)


    const overAllClick = () => {
        if (props.mode !== props.type) {
            props.setMode(props.type)
        }
    }

    const colorUpdateHandler = (e) => {
        // console.log(e.target.value)
        // setColor(e.target.value);

        const input = e.target.value


        if (e.target.value.length < 7) {
            setColor(e.target.value)

        }



        // console.log(e.target.value)



        if (input.length === 7) {
            if (input[0] === "#") {
                const data = {
                    material: props.type,
                    color: e.target.value
                }
                setColor(e.target.value)
                props.colorUpdateHandler(data)
            }
        } else if (input.length === 6) {
            const data = {
                material: props.type,
                color: `#${e.target.value}`
            }
            props.colorUpdateHandler(data)
        }
    }


    return (


        <Box
            onClick={overAllClick}
            sx={{

                padding: props.mode !== props.type ? "10px 12px" : "20px 12px",
                boxShadow: props.mode === props.type ? "0px 0px 6px 3px #9f9e9e47" : "none",
                borderRadius: "3px",
                backgroundColor: props.mode === props.type ? "#383A3A" : "#F5F5F5",
                margin: "5px 0",
                borderRadius: props.mode === props.type ? "16px" : "52px",
                overflow: "hidden",
                position: "relative",
                transitionProperty: "filter",
                transitionDuration: " 0.5s",
                cursor: props.mode !== props.type ? 'pointer' : "default",
                '&:hover': {
                    filter: props.mode === props.type ? "none" : "brightness(90%)",
                },
            }}
        >


            <Box
                onClick={() => { props.setMode(props.type) }}
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    marginTop: "5px",
                    cursor: 'pointer',

                }}
            >
                <Typography
                    sx={{
                        marginRight: "auto",
                        color: props.mode === props.type ? "white" : "#383A3A",
                        fontWeight: "600",
                        fontSize: "12px",
                        textTransform: "capitalize",
                        fontFamily: 'Mona Sans',
                    }}

                >{props.title} </Typography>

                {props.mode !== props.type &&

                    <Box
                        sx={{
                            backgroundColor: props.color,
                            width: "25px",
                            height: '25px',
                            borderRadius: "100px",
                            // border: "2px solid white", 
                            // boxShadow: "0px 0px 3px 2px #35353526",
                        }}

                    ></Box>
                }
                {props.mode === props.type &&
                    <Typography
                        sx={{
                            color: "black",
                            backgroundColor: "white",
                            fontSize: "0.7rem",
                            fontFamily: 'Mona Sans',
                            borderRadius: "50px",
                            padding: "3px 12px",
                            fontWeight: "600",
                            transitionProperty: "background-color, box-shadow,transform,filter",
                            transitionDuration: " 0.5s",
                            '&:hover': {
                                transform: "scale(1.1)",
                            },
                        }}
                    >DONE</Typography>
                }
            </Box>

            {
                props.mode === props.type &&
                <Box
                sx={{
                    position: "relative",
                    margin: "15px 10px 5px 0px",
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        width: "500px",
                        backgroundColor: "#777777a3",
                        height: "1px",
                        left: "-100px",
                        // left: "-30",
                    }}
                ></Box>
            </Box>

            }


            <Collapse
                in={props.mode === props.type} timeout="auto" unmountOnExit>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        marginTop: "10px",

                    }}
                >
                    <Box
                        sx={{
                            marginRight: "auto",
                            fontFamily: 'Mona Sans',
                            fontSize: "0.9rem",
                            fontWeight: "400",
                            color: props.mode === "cover" ? "white" : "black"
                        }}
                    >
                        Show/Hide
                    </Box>



                    <IOSSwitch
                        sx={{
                            '& .MuiSwitch-switchBase': {
                                '&.Mui-checked': {
                                    '& + .MuiSwitch-track': {
                                        backgroundColor: `#38B8AB`,
                                    },
                                }
                            }
                        }}
                        onChange={props.coverHandleChange}
                        defaultChecked={props.coverShow}
                    />

                </Box>


            </Collapse>

            <Collapse
                in={props.mode === props.type} timeout="auto" unmountOnExit>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                        marginTop: "15px",
                        pointerEvents: props.coverShow ? "auto" : "none",
                        opacity: props.coverShow ? "1" : "0.5",
                    }}
                >
                    <Box
                        sx={{
                            color: "white",
                            marginRight: "auto",
                            fontFamily: 'Mona Sans',
                            fontSize: "0.8rem",
                            fontWeight: "600",
                        }}
                    >
                        Color
                    </Box>

                    <Box
                        sx={{
                            color: "#7F7F7F",
                            fontSize: "0.7rem",
                            display: "flex",
                            alignItems: "center",
                            border: "2px solid #777777",

                        }}
                    >
                        <Typography
                            sx={{
                                color: "white",
                                padding: "1px 6px",
                                fontWeight: "600",
                                fontSize: "0.8rem",
                                fontFamily: 'Mona Sans',
                                backgroundColor: "#777777",

                            }}
                        >
                            HEX
                        </Typography>
                        <Box
                            onInput={colorUpdateHandler}
                            component="input"
                            value={color[0] === "#" ? color.substring(1) : color}
                            sx={{
                                width: "70px",
                                color: "white",
                                fontWeight: "500",
                                textAlign: 'center',
                                borderRadius: "0",
                                fontWeight: "600",
                                border: "0px",
                                height: "19px" ,
                                boxShadow: "none",
                                cursor: 'pointer',
                                backgroundColor: "#ffffff00",
                                textTransform: "uppercase",
                                '&:focus': {
                                    border: "0px",
                                }
                            }}
                        ></Box>
                    </Box>
                    <Box
                        sx={{
                            border: "0px solid #777777",
                            padding: "0",
                            backgroundColor: props.color,
                            width: "60px",
                            height: "24px",
                            borderRadius: "4px",
                            cursor: 'pointer',
                        }}
                    >
                        <input style={{
                            opacity: "0",
                            cursor: 'pointer',
                        }} onChange={colorUpdateHandler} type="color" value={props.color} />

                    </Box>
                </Box>

            </Collapse>

        </Box >

    )

}


export default AeroComp
