import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { nanoid } from 'nanoid';
import { GroundProjectedSkybox } from 'three/addons/objects/GroundProjectedSkybox.js';
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';

import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js';
import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass.js';
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';
import { BokehPass } from 'three/addons/postprocessing/BokehPass.js';

import { FilmPass } from 'three/addons/postprocessing/FilmPass.js';
import { VignetteShader } from 'three/addons/shaders/VignetteShader.js';
import JSZip from 'jszip';
import FileSaver from 'file-saver';

import { GUI } from 'three/addons/libs/lil-gui.module.min.js';
import { Lensflare, LensflareElement } from 'three/addons/objects/Lensflare.js';
import { gsap } from 'gsap';

import { USDZExporter } from 'three/examples/jsm/exporters/USDZExporter.js';
import WebXRModule from './WebXRModule';
import { ShieldSharp } from '@mui/icons-material';

import { logoTextureBuilder } from './logoTextureBuilder';

import {
  CSS2DRenderer,
  CSS2DObject,
} from 'three/addons/renderers/CSS2DRenderer.js';

import MaterialDataSet from '../ConfigurationComp/Utility/MaterialDataSet';

class ThreejsModule {
  constructor(data) {
    this.canvas = data.canvasRef.current;
    this.setLoading = data.setLoading;
    this.css2Dref = data.css2Dref;
    this.configureData = data.configureData;
    this.setConfigureData = data.setConfigureData;
    this.setLoadingTexture = data.setLoadingTexture;
    this.setLoadHandler = data.setLoadHandler;
    this.configureData.isInterior = false;
    this.setIsInterior = data.setIsInterior;
    this.setConfigureMode = data.setConfigureMode;
    this.addGenerating = data.addGenerating;
    this.setGeneratingTotal = data.setGeneratingTotal;
    this.materialDataSet = MaterialDataSet;
    this.videoDiv = document.getElementById('screen-video');

    this.screenVideoState = 'start';

    this.isFleet = data.isFleet;

    this.prevCarType = data.configureData.carType;

    this.zip = new JSZip();

    this.setIsInterior(false);

    this.contronMimDistance = 2.5;

    this.contronMaxDistance = 6;

    this.contronMaxDistanceNear = 1;

    this.frontSprite = null;
    this.backSprite = null;
    this.frontSpriteGrp = new THREE.Group();
    this.backSpriteGrp = new THREE.Group();

    this.hotspotList = [];

    this.contronMaxDistanceNear = 1;

    this.frontSprite = null;
    this.backSprite = null;
    this.frontSpriteGrp = new THREE.Group();
    this.backSpriteGrp = new THREE.Group();

    this.hotspotList = [];

    this.doorOpen = false;
    this.truckOpen = false;
    this.cameraState = 'outside';

    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    this.hdrURLs = isMobile
      ? {
          forest: './assets/hdri/Museum_1k_v2.hdr',
          city: './assets/hdri/canary_wharf_1k_v2.hdr',
          beach: './assets/hdri/spiaggia_di_mondello_1k.hdr',
          fleet: './assets/hdri/fleet_1k.hdr',
        }
      : {
          forest: './assets/hdri/Museum_4k_v2.hdr',
          city: './assets/hdri/canary_wharf_4k_v2.hdr',
          beach: './assets/hdri/spiaggia_di_mondello_4k.hdr',
          fleet: './assets/hdri/fleet.hdr',
        };

    this.videoFiles = {
      intro: './assets/videos/Intro.mp4',
      loop: './assets/videos/Loop.mp4',
      outro: './assets/videos/Outro.mp4',
    };

    this.gltfLoader = new GLTFLoader();
    this.hdrLoader = new RGBELoader();
    this.arScene = new THREE.Group();

    this.skybox = null;

    this.lights = [];
    this.car = null;
    this.lensflare = null;
    this.darkMaterial = new THREE.MeshBasicMaterial({ color: 'black' });
    this.controlsGrp = new THREE.Group();

    this.animateFrame = null;

    this.configureMaterials = {};

    // console.log(data.setLoading)

    this.camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.05,
      50
    );

    this.controlCamera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.05,
      50
    );

    this.camera.position.set(4, 1.5, 4);
    this.controlCamera.position.set(4, 1.5, 4);

    this.previewCamera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.1,
      200
    );

    this.previewCamera.aspect = 2000 / 1500;
    this.previewCamera.updateProjectionMatrix();

    this.scene = new THREE.Scene();

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true,
    });

    this.maxAnisotropy = this.renderer.capabilities.getMaxAnisotropy();

    isMobile
      ? this.renderer.setPixelRatio(window.devicePixelRatio * 0.4)
      : this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.renderer.useLegacyLights = false;
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.toneMappingExposure = 0.75;
    this.renderer.domElement.style.zIndex = -2;
    this.renderer.domElement.style.position = 'absolute';
    this.renderer.xr.enabled = true;

    this.labelRenderer = new CSS2DRenderer();
    this.labelRenderer.setSize(window.innerWidth, window.innerHeight);
    this.labelRenderer.domElement.style.position = 'absolute';
    this.labelRenderer.domElement.style.top = '0px';

    this.previewRenderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true,
    });

    // this.previewRenderer.setPixelRatio(window.devicePixelRatio * 0.6);
    isMobile
      ? this.previewRenderer.setPixelRatio(window.devicePixelRatio * 0.4)
      : this.previewRenderer.setPixelRatio(window.devicePixelRatio);
    this.previewRenderer.setSize(3500, 2250);
    this.previewRenderer.useLegacyLights = false;
    this.previewRenderer.shadowMap.enabled = true;
    this.previewRenderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
    this.previewRenderer.outputEncoding = THREE.sRGBEncoding;
    this.previewRenderer.toneMappingExposure = 0.75;

    // this.previewCamera.aspect = 4 / 3;
    // this.previewCamera.updateProjectionMatrix();

    this.previewRenderer.domElement.style.zIndex = -2;
    this.previewRenderer.domElement.style.position = 'absolute';

    this.leftDoor = null;
    this.rightDoor = null;
    this.leftTrunkDoor = null;
    this.rightTrunkDoor = null;
    this.backTrunkDoor = null;
    this.initComposer();

    this.wheel = null;

    this.cube = null;

    this.suv = null;
    this.van = null;
    this.truck = null;

    this.firstVideoPlay = true;

    this.webxr = new WebXRModule({
      scene: this.scene,
      camera: this.camera,
      renderer: this.renderer,
    });

    this.webxr.onEnd = () => {
      console.log('webxr on end');
      this.skybox.visible = true;

      // this.initComposer()

      this.animate();
      console.log('webxr on start web');

      this.onWindowResize();
    };
    // this.webxr.onSelectStart = ()=>{
    //     console.log("webxr on select start")
    // }

    // this.webxr.onSelectEnd = ()=>{
    //     console.log("webxr on select end")
    // }
    this.rear = null;

    this.controls = new OrbitControls(
      this.controlCamera,
      this.labelRenderer.domElement
    );
    this.controls.maxPolarAngle = 1.5;
    this.controls.enabled = false;
    this.controls.enablePan = false;
    this.controls.maxZoom = 15;
    this.controls.minZoom = 5;

    this.controls.minDistance = this.contronMimDistance;
    this.controls.rotateSpeed = 0.3;

    this.controls.keys = {
      LEFT: 'ArrowLeft', //left arrow
      UP: 'ArrowUp', // up arrow
      RIGHT: 'ArrowRight', // right arrow
      BOTTOM: 'ArrowDown', // down arrow
    };

    this.renderCancel = false;

    this.logo = null;

    const position = this.configureData.logoPosition;

    this.logoTexture = new logoTextureBuilder({
      logoCanvas: document.querySelector('#logo-canvas'),
      size: {
        width: 300,
        height: 300,
      },
      logoFile: data.configureData.logoUrl,
      scaleInput: document.querySelector('#logo-scale'),
      fileInput: document.querySelector('#logo-file-input'),
      position: new THREE.Vector3(position[0], position[1], position[2]),
      scale: this.configureData.logoScale,
    });

    // this.logoTexture.loadLogo(this.configureData.logoUrl)

    if (this.configureData.logoUrl) {
      this.logoTexture.loadLogo(this.configureData.logoUrl);
    }

    this.logoTexture.onFinalRender = (texture, position, scale) => {
      // console.log(texture)
      // texture.needsUpdate = true;
      if (this.logo) this.logo.material.map = texture;

      // console.log(position, scale)

      this.configureData.logoScale = scale;
      this.configureData.logoPosition = [position.x, position.y, position.z];

      const newData = {
        ...this.configureData,
      };

      this.setConfigureData(newData);
    };

    this.cameraTagetRef = new THREE.Group();
    this.rotationTaget = new THREE.Group();
    this.targetQuaternion = new THREE.Quaternion();

    this.slerpEnd = true;
    this.slerpEndEvent = () => {
      this.slerpEnd = true;
      this.scene.attach(this.camera);
      this.scene.attach(this.controlCamera);
      if (this.needEnterInterior) {
        this.enterInterior();
      } else {
        console.log('slearp end');
        this.controls.enabled = true;
      }
    };

    this.scene.add(this.rotationTaget);
    this.scene.add(this.cameraTagetRef);

    // order
    this.rotationTaget.rotation.order = 'YZX';
    this.cameraTagetRef.rotation.order = 'YZX';

    this.needEnterInterior = false;

    // const arrowHelper = new THREE.ArrowHelper(new THREE.Vector3(0, 0, 1), new THREE.Vector3(0, 0, 0), 1, 0xffff00);
    // this.cameraTagetRef.add(arrowHelper);

    // const axesHelper = new THREE.AxesHelper(3);
    // this.rotationTaget.add(axesHelper);
  }

  initRender() {}

  scaleInputStart() {
    this.logoTexture.onScaleStart();
  }
  scaleInputEnd() {}
  onScaleInput(value) {
    this.logoTexture.onScale(value);
    // console.log(value)
  }

  initComposer() {
    const renderScene = new RenderPass(this.scene, this.camera);
    const shaderVignette = VignetteShader;
    const effectVignette = new ShaderPass(shaderVignette);

    effectVignette.uniforms['offset'].value = 0.45;
    effectVignette.uniforms['darkness'].value = 1.6;

    const bloomPass = new UnrealBloomPass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      1.5,
      0.4,
      0.85
    );
    bloomPass.threshold = 0;
    bloomPass.strength = 1;
    bloomPass.radius = 0.5;

    this.bloomComposer = new EffectComposer(this.renderer);
    this.bloomComposer.renderToScreen = false;
    this.bloomComposer.addPass(renderScene);
    this.bloomComposer.addPass(bloomPass);

    const mixPass = new ShaderPass(
      new THREE.ShaderMaterial({
        uniforms: {
          baseTexture: { value: null },
          bloomTexture: { value: this.bloomComposer.renderTarget2.texture },
        },
        vertexShader: `
                varying vec2 vUv;

                void main() {
            
                  vUv = uv;
            
                  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            
                }
                `,
        fragmentShader: `
                uniform sampler2D baseTexture;
                uniform sampler2D bloomTexture;
            
                varying vec2 vUv;
            
                void main() {
            
                  gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );
            
                }
                `,
        defines: {},
      }),
      'baseTexture'
    );
    mixPass.needsSwap = true;

    const bokehPass = new BokehPass(this.scene, this.camera, {
      focus: 500,
      aperture: 5,
      maxblur: 0.01,
    });

    const effectFilm = new FilmPass(0.1, 0.015, 248, false);

    const outputPass = new OutputPass(THREE.ACESFilmicToneMapping);
    outputPass.toneMappingExposure = 1;

    this.finalComposer = new EffectComposer(this.renderer);
    this.finalComposer.addPass(renderScene);
    this.finalComposer.addPass(mixPass);
    // this.finalComposer.addPass(bokehPass);
    this.finalComposer.addPass(outputPass);
    // this.finalComposer.addPass(effectFilm);
    this.finalComposer.addPass(effectVignette);

    const previewScene = new RenderPass(this.scene, this.previewCamera);

    this.previewBloomComposer = new EffectComposer(this.previewRenderer);
    this.previewBloomComposer.renderToScreen = false;
    this.previewBloomComposer.addPass(previewScene);
    this.previewBloomComposer.addPass(bloomPass);

    const previewMixPass = new ShaderPass(
      new THREE.ShaderMaterial({
        uniforms: {
          baseTexture: { value: null },
          bloomTexture: {
            value: this.previewBloomComposer.renderTarget2.texture,
          },
        },
        vertexShader: `
                varying vec2 vUv;

                void main() {
            
                  vUv = uv;
            
                  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            
                }
                `,
        fragmentShader: `
                uniform sampler2D baseTexture;
                uniform sampler2D bloomTexture;
            
                varying vec2 vUv;
            
                void main() {
            
                  gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );
            
                }
                `,
        defines: {},
      }),
      'baseTexture'
    );
    previewMixPass.needsSwap = true;

    this.previewComposer = new EffectComposer(this.previewRenderer);
    this.previewComposer.addPass(previewScene);
    this.previewComposer.addPass(previewMixPass);
    this.previewComposer.addPass(outputPass);

    const effectController = {
      focus: 60,
      aperture: 3,
      maxblur: 0.03,
    };
    // bokehPass.uniforms['focus'].value = 60
    // bokehPass.uniforms['aperture'].value =5
    // bokehPass.uniforms['maxblur'].value = 0.03

    const matChanger = function () {
      bokehPass.uniforms['focus'].value = effectController.focus;
      bokehPass.uniforms['aperture'].value =
        effectController.aperture * 0.00001;
      bokehPass.uniforms['maxblur'].value = effectController.maxblur;
    };

    // const gui = new GUI();
    // gui.add(effectController, 'focus', 0.0, 200.0, 1).onChange(matChanger);
    // gui.add(effectController, 'aperture', 0, 10, 0.1).onChange(matChanger);
    // gui.add(effectController, 'maxblur', 0.0, 0.02, 0.001).onChange(matChanger);
    // gui.close();

    matChanger();
  }

  async init() {
    this.setIsInterior(false);

    this.setLoading(0);
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();

    // this.previewRenderer
    document.body.appendChild(this.previewRenderer.domElement);

    document.body.appendChild(this.renderer.domElement);
    document.body.appendChild(this.labelRenderer.domElement);
    this.labelRenderer.domElement.style.zIndex = '-1';

    const frontDiv = document.createElement('div');
    const frontImg = document.createElement('img');
    frontDiv.appendChild(frontImg);
    frontImg.className = 'label';
    frontImg.src = './assets/images/icons/Hot_Spot.svg';

    this.frontLable = new CSS2DObject(frontDiv);
    this.frontLable.position.set(0, 1.2, -0.1);
    this.scene.add(this.frontLable);
    this.frontLable.visible = false;

    frontImg.addEventListener('pointerdown', () => {
      this.setConfigureMode(null);
      if (this.cameraState != 'outside') {
        this.enterInterior();
        // this.setIsInterior(true)
      } else {
        this.updateCameraPosition('door');
      }
    });

    //

    const backDiv = document.createElement('div');
    const backImg = document.createElement('img');
    backDiv.appendChild(backImg);
    backImg.className = 'label';
    backImg.src = './assets/images/icons/Hot_Spot.svg';

    this.backLable = new CSS2DObject(backDiv);
    this.backLable.position.set(0, 1, -1.2);
    this.scene.add(this.backLable);

    this.backLable.visible = false;

    backImg.addEventListener('pointerdown', () => {
      this.setConfigureMode(null);
      if (this.cameraState != 'outside') {
        this.enterBackTruck();
        // this.setIsInterior(true)
      } else {
        this.updateCameraPosition('back');
      }
    });

    const screenDiv = document.createElement('div');
    const screenImg = document.createElement('img');
    screenDiv.appendChild(screenImg);
    screenImg.className = 'label';
    screenImg.src = './assets/images/icons/Hot_Spot.svg';

    this.screenLable = new CSS2DObject(screenDiv);
    this.screenLable.position.set(-0.42, 1.05, 0.6);
    this.scene.add(this.screenLable);

    this.screenLable.visible = false;

    screenImg.addEventListener('pointerdown', () => {
      this.setConfigureMode(null);
      this.moveToScreen();
      this.screenLable.visible = false;
    });

    if (this.isFleet) {
      this.switchBackground('fleet');
    } else {
      this.switchBackground('forest');
    }

    const textureLoader = new THREE.TextureLoader();

    const textureFlare0 = textureLoader.load(
      './assets/images/lensflare/lensflare2.png'
    );
    const textureFlare2 = textureLoader.load(
      './assets/images/lensflare/lensflare2.png'
    );
    const textureFlare3 = textureLoader.load(
      './assets/images/lensflare/lensflare4.png'
    );
    const textureFlare1 = textureLoader.load(
      './assets/images/lensflare/lensflare1.png'
    );

    const light = new THREE.PointLight(0xffffff, 0, 2000, 0);
    light.color.setHSL(1, 1, 1);
    light.position.set(14, 9.5, 9.5);
    this.scene.add(light);

    this.lensflare = new Lensflare();
    this.lensflare.addElement(
      new LensflareElement(textureFlare0, 700, 0, light.color)
    );
    this.lensflare.addElement(new LensflareElement(textureFlare1, 600, 0.01));
    this.lensflare.addElement(new LensflareElement(textureFlare3, 60, 0.1));
    this.lensflare.addElement(new LensflareElement(textureFlare3, 70, 0.2));
    this.lensflare.addElement(new LensflareElement(textureFlare2, 120, 0.25));
    this.lensflare.addElement(new LensflareElement(textureFlare3, 70, 0.3));
    light.add(this.lensflare);

    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();

    window.addEventListener('pointerdown', pointerdownHandler.bind(this));

    function pointerdownHandler(event) {
      // calculate pointer position in normalized device coordinates
      // (-1 to +1) for both components
      // console.log(this.camera)
      // console.log(this.controls.target)
      // pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
      // pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;
      // raycaster.setFromCamera(pointer, this.camera)
      // // const intersects = raycaster.intersectObject(this.skybox);
      // const intersects = raycaster.intersectObjects(this.hotspotList);

      console.log(this.controlCamera.position);
    }

    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshStandardMaterial({ color: 0x00ff00 });
    this.cube = new THREE.Mesh(geometry, material);
    // this.scene.add(this.cube);

    this.scene.add(this.arScene);

    this.gltfLoader.load(
      './assets/models/aio7.glb',
      async function (gltf) {
        // this.controls.enableDamping = true;
        // this.controls.enabled = true
        // this.controls.maxDistance = 8
        this.screen = gltf.scene.getObjectByName('screen1');
        this.screenTexture = this.screen.material.map;

        this.videoTexture = new THREE.VideoTexture(this.videoDiv);
        this.videoTexture.flipY = false;
        this.videoTexture.encoding = THREE.sRGBEncoding;

        this.screen.material.map = this.videoTexture;

        const wheel1 = gltf.scene.getObjectByName('wheel1');
        const wheel2 = gltf.scene.getObjectByName('wheel2');
        const wheel3 = gltf.scene.getObjectByName('wheel3');
        const wheel4 = gltf.scene.getObjectByName('whee4');

        // console.log(wheel2);

        this.setLoadHandler();

        console.log(gltf);
        this.arScene.position.z = -2;

        var enterTL = gsap.timeline();

        enterTL.to(
          this.arScene.position,
          {
            duration: 2,
            ease: 'sine.inOut',
            delay: 0.5,
            z: 0,
            onComplete: () => {
              this.controls.enableDamping = true;
              this.controls.enabled = true;
              this.controls.maxDistance = this.contronMaxDistance;
            },
          },
          'enter'
        );

        enterTL.to(
          wheel1.rotation,
          {
            duration: 2,
            ease: 'sine.inOut',
            delay: 0.5,
            x: 2 * Math.PI,
          },
          'enter'
        );

        enterTL.to(
          wheel2.rotation,
          {
            duration: 2,
            ease: 'sine.inOut',
            delay: 0.5,
            x: 2 * Math.PI,
          },
          'enter'
        );

        enterTL.to(
          wheel3.rotation,
          {
            duration: 2,
            ease: 'sine.inOut',
            delay: 0.5,
            x: 2 * Math.PI,
          },
          'enter'
        );

        enterTL.to(
          wheel4.rotation,
          {
            duration: 2,
            ease: 'sine.inOut',
            delay: 0.5,
            x: 2 * Math.PI,
          },
          'enter'
        );

        gltf.scene.scale.set(0.01, 0.01, 0.01);

        gltf.scene.position.z = 1.2;

        const shadowTexture = textureLoader.load('./assets/images/shadow.png');
        // shadowTexture.encoding = THREE.sRGBEncoding;
        this.car = gltf.scene;

        // this.car.position.z = 0.3
        // this.car.scale.set(0.1, 0.1, 0.1)
        const light1 = gltf.scene.getObjectByName('base86001');
        light1.userData.light = true;
        const light2 = gltf.scene.getObjectByName('base33001');
        light2.userData.light = true;
        const light3 = gltf.scene.getObjectByName('base49001');
        light3.userData.light = true;
        const light4 = gltf.scene.getObjectByName('base55001');
        light4.userData.light = true;
        const light5 = gltf.scene.getObjectByName('base29001');
        light5.userData.light = true;
        const light6 = gltf.scene.getObjectByName('base43001');
        light6.userData.light = true;

        const light7 = gltf.scene.getObjectByName('base79001');
        light7.userData.light = true;
        const light8 = gltf.scene.getObjectByName('base60001');
        light8.userData.light = true;
        const light9 = gltf.scene.getObjectByName('car78001');
        light9.userData.light = true;

        const lightMaterial = new THREE.MeshStandardMaterial({
          color: 0xa6bcff,
        });
        light1.material = lightMaterial;
        light2.material = lightMaterial;
        light3.material = lightMaterial;
        light4.material = lightMaterial;
        light5.material = lightMaterial;
        light6.material = lightMaterial;

        const lightMaterialRed = new THREE.MeshStandardMaterial({
          color: 0x600000,
        });
        light7.material = lightMaterialRed;
        light8.material = lightMaterialRed;
        light9.material = lightMaterialRed;

        const glass1 = gltf.scene.getObjectByName('base81001');
        glass1.userData.light = true;
        const glass2 = gltf.scene.getObjectByName('base45001');
        glass2.userData.light = true;

        this.lights.push(light1);
        this.lights.push(light2);
        this.lights.push(light3);
        this.lights.push(light4);
        this.lights.push(light5);
        this.lights.push(light6);
        this.lights.push(light7);
        this.lights.push(light8);
        this.lights.push(light9);

        this.lights.push(glass1);
        this.lights.push(glass2);

        // this.body = gltf.scene.getObjectByName("suv106")
        // this.wheel = gltf.scene.getObjectByName("suv106")

        // //
        const logoTexture = textureLoader.load('./assets/images/logo.png');
        logoTexture.flipY = false;
        logoTexture.encoding = THREE.sRGBEncoding;
        this.logo = gltf.scene.getObjectByName('van10001');

        const shadowMaterialLogo = new THREE.MeshBasicMaterial({
          color: 0xff0000,
        });

        console.log(this.logo);
        this.logo.material.map = logoTexture;

        // this.logo.material = shadowMaterialLogo

        // this.logo.material.color = new THREE.Color( 0xff0000 );
        // this.logo.material.transparent = 0.9

        this.suv = gltf.scene.getObjectByName('car');
        this.van = gltf.scene.getObjectByName('van3');
        this.truck = gltf.scene.getObjectByName('truck');

        this.suv.visible = false;
        // this.van.visible = false
        this.truck.visible = false;

        this.leftDoor = gltf.scene.getObjectByName('left_door');
        this.rightDoor = gltf.scene.getObjectByName('right_door');

        this.leftTrunkDoor = gltf.scene.getObjectByName('van7001');
        this.rightTrunkDoor = gltf.scene.getObjectByName('van5001');
        this.backTrunkDoor = gltf.scene.getObjectByName('back_truck');

        this.aeroCover = gltf.scene.getObjectByName('base111001');
        this.aeroCover2 = gltf.scene.getObjectByName('base108001');
        this.aeroCover3 = gltf.scene.getObjectByName('base106001');

        this.aeroCover4 = gltf.scene.getObjectByName('base95001');
        this.aeroCover5 = gltf.scene.getObjectByName('base92001');
        this.aeroCover6 = gltf.scene.getObjectByName('base90001');

        this.aeroCover7 = gltf.scene.getObjectByName('base9001');
        this.aeroCover8 = gltf.scene.getObjectByName('base4001');
        this.aeroCover9 = gltf.scene.getObjectByName('base6001');

        this.aeroCover10 = gltf.scene.getObjectByName('base103');
        this.aeroCover11 = gltf.scene.getObjectByName('base98');
        this.aeroCover12 = gltf.scene.getObjectByName('base100');

        this.configureMaterials = {
          body: gltf.scene.getObjectByName('van4001').material,
          front: gltf.scene.getObjectByName('base76001').material,
          rear: gltf.scene.getObjectByName('base40001').material,
          roof: gltf.scene.getObjectByName('truck002').material,
          rocket: gltf.scene.getObjectByName('base75001').material,
          window: gltf.scene.getObjectByName('base13001').material,
          trim: gltf.scene.getObjectByName('base11001').material,
          grille: gltf.scene.getObjectByName('base36001').material,
          cover: gltf.scene.getObjectByName('base103001').material,
          rim: gltf.scene.getObjectByName('base7001').material,
          ceiling: gltf.scene.getObjectByName('base42001').material,
          // screen: gltf.scene.getObjectByName('base67001').material,
          seat: gltf.scene.getObjectByName('base27001').material,
          wall: gltf.scene.getObjectByName('base10001').material,
          wallLower: gltf.scene.getObjectByName('base10001').material,
          floor: gltf.scene.getObjectByName('base30001').material,
        };

        // this.aeroCover.visible = this.configureData.aeroCover

        this.hideCover(this.configureData.aeroCover);

        //     this.configureMaterials = {
        //     body: gltf.scene.getObjectByName("body").material,
        //     front: gltf.scene.getObjectByName("body").material,
        //     rear: gltf.scene.getObjectByName("body").material,
        //     roof: gltf.scene.getObjectByName("body").material,
        //     rocket: gltf.scene.getObjectByName("body").material,
        //     window: gltf.scene.getObjectByName("body").material,
        //     trim: gltf.scene.getObjectByName("body").material,
        //     grill: gltf.scene.getObjectByName("body").material,
        //     tire: gltf.scene.getObjectByName("body").material,
        //     rim: gltf.scene.getObjectByName("body").material,
        // }

        // this.body.material.lightMap = lightTexture
        // console.log(this.body)

        // this.body.scale.set(1.1, 1.1, 1.1)

        // this.rear = gltf.scene.getObjectByName("suv_reat_test")

        // this.body.material.clearcoat = 1

        shadowTexture.flipY = false;
        const shadowMaterial = new THREE.MeshBasicMaterial({
          map: shadowTexture,
          transparent: true,
        });
        const plane = new THREE.Mesh(
          new THREE.PlaneGeometry(6, 6),
          shadowMaterial
        );

        plane.rotateX(-Math.PI / 2);

        plane.position.z = -0.1;
        this.arScene.add(plane);

        this.car.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            // child.material.lightMap = lightTexture
            child.userData.defaultMatt = child.material;
            child.castShadow = true;
          }
        });

        this.arScene.add(gltf.scene);
        this.setLoading(undefined);

        this.initData();

        this.logoTexture.render();
      }.bind(this),
      function (xhr) {
        // console.log("loading legs 3d models");
        const loadingAmount = Math.floor((xhr.loaded / xhr.total) * 100);
        console.log(loadingAmount);
        this.setLoading(loadingAmount);
      }.bind(this),
      // called when loading has errors
      function (error) {
        console.log('An error happened when loading table legs 3d models');
      }.bind(this)
    );

    window.addEventListener('resize', this.onWindowResize.bind(this));

    window.addEventListener('webxr-render-image', this.renderImages.bind(this));
    window.addEventListener(
      'webxr-render-feature-image',
      this.renderFeatureImage.bind(this)
    );

    this.animate();
  }

  initData() {
    // console.log(this.configureData)
    Object.keys(this.configureMaterials).map((material) => {
      // console.log(material);
      const color = this.configureData.colors[material].color;
      this.updateColor({ material, color });

      if (material === 'floor') {
        const materialInput = this.materialDataSet.filter(
          (word) => word.title === this.configureData.colors.floor.material
        );

        //  console.log({materialInput})
        const data = {
          material: materialInput[0],
          mode: 'floor',
        };
        this.updateMaterial(data);
      }
    });

    // data.configureData.carType
    this.switchCar(this.configureData.carType);
  }

  hideCover = (value) => {
    this.aeroCover.visible = value;
    this.aeroCover2.visible = value;
    this.aeroCover3.visible = value;
    this.aeroCover4.visible = value;
    this.aeroCover5.visible = value;
    this.aeroCover6.visible = value;
    this.aeroCover7.visible = value;
    this.aeroCover8.visible = value;
    this.aeroCover9.visible = value;
    this.aeroCover10.visible = value;
    this.aeroCover11.visible = value;
    this.aeroCover12.visible = value;
  };

  async switchBackground(value) {
    this.setLoadingTexture(true);
    const url = this.hdrURLs[value];
    // console.log({ url });
    const envMap = await this.hdrLoader.loadAsync(url);
    envMap.mapping = THREE.EquirectangularReflectionMapping;
    this.scene.remove(this.skybox);
    this.skybox = new GroundProjectedSkybox(envMap);
    this.skybox.scale.setScalar(12);
    this.scene.add(this.skybox);
    this.scene.environment = envMap;
    this.skybox.height = 10;
    this.setLoadingTexture(false);

    // console.log(this.skybox)
  }

  headLight(value) {
    console.log(value);

    this.lights.forEach((light) => {
      light.userData.light = !value;
    });

    // this.body.material.clearcoat = 0
  }
  removeLogo() {
    this.logoTexture.removeLogo();
  }

  powerOff() {
    if (this.screenVideoState === 'looping') {
      this.screenVideoState = 'ending';
      this.videoDiv.src = this.videoFiles.outro;
      this.videoDiv.loop = false;
      this.videoDiv.load();
      this.videoDiv.play();
    } else if (this.screenVideoState === 'start') {
      this.powerOn();
    }
  }

  powerOn() {
    if (this.screenVideoState === 'start') {
      this.screenVideoState = 'starting';
      this.videoDiv.src = this.videoFiles.intro;
      this.videoDiv.loop = false;
      this.videoDiv.load();
      this.videoDiv.play();
    }
  }

  onVideoEnd() {
    console.log('video end');

    if (this.screenVideoState === 'starting') {
      this.screenVideoState = 'looping';
      this.videoDiv.src = this.videoFiles.loop;
      this.videoDiv.loop = true;
      this.videoDiv.load();
      this.videoDiv.play();
    } else if (this.screenVideoState === 'ending') {
      this.screenVideoState = 'start';
    }
  }

  renderImages(e) {
    this.setDoorState(true);

    let totalImages = 0;

    this.addGenerating(0);
    this.prevCarType = this.configureData.carType;

    const status = e.detail.vehicles;
    const filename = e.detail.filename;
    const asRoot = e.detail.filename !== 'AIO FLEET';

    Object.keys(status).forEach((key) => {
      if (status[key]) {
        console.log({ key });
        totalImages += 8;
        console.log({ totalImages });
        this.renderPreview(key, asRoot);
      }
    });

    this.setGeneratingTotal(totalImages);

    this.zip.generateAsync({ type: 'blob' }).then(
      function (content) {
        // see FileSaver.js
        this.addGenerating('done');
        this.setDoorState(false);
        FileSaver.saveAs(content, filename);
        console.log(this.configureData);
        this.switchCar(this.prevCarType);
      }.bind(this)
    );
  }

  renderPreview(key, asRoot) {
    this.previewCamera.aspect = 3500 / 2250;
    this.previewCamera.updateProjectionMatrix();
    this.previewRenderer.setPixelRatio(window.devicePixelRatio);
    this.previewRenderer.setSize(3500, 2250);
    this.previewRenderer.render(this.scene, this.previewCamera);

    // 8000x6000

    const facter = {
      x: 2,
      y: 1.4,
      z: 2.7,
    };

    const nameConvert = {
      car: 'AIO CAR',
      van: 'AIO VAN',
      truck: 'AIO TRUCK',
    };

    const name = nameConvert[key];

    const previewLocation = [
      {
        position: new THREE.Vector3(0, 1.4, 3.4),
        name: `01 ${name} Front`,
      },
      {
        position: new THREE.Vector3(0, 1.4, -3.4),
        name: `05 ${name} Back`,
      },
      {
        position: new THREE.Vector3(3, 1.4, 0),
        name: `03 ${name} Side Left`,
      },
      {
        position: new THREE.Vector3(-3, 1.4, 0),
        name: `07 ${name} Side Right`,
      },
      {
        position: new THREE.Vector3(facter.x, facter.y, facter.z),
        name: `02 ${name} Front Left`,
      },
      {
        position: new THREE.Vector3(-facter.x, facter.y, facter.z),
        name: `08 ${name} Front Right`,
      },
      {
        position: new THREE.Vector3(-facter.x, facter.y, -facter.z),
        name: `06 ${name} Back Right`,
      },
      {
        position: new THREE.Vector3(facter.x, facter.y, -facter.z),
        name: `04 ${name} Back Left`,
      },
      {
        end: true,
      },
    ];

    this.switchCar(key);
    const foldername = asRoot ? '' : nameConvert[key];

    const img = this.zip.folder(foldername);
    const fetchData = (file) => fetch(file).then((res) => res.blob());
    previewLocation.forEach((data) => {
      console.log(data);
      if (data.end) {
        // this.previewCamera.aspect = window.innerWidth / window.innerHeight;
        // this.previewCamera.updateProjectionMatrix();
        // this.previewRenderer.setSize(window.innerWidth, window.innerHeight);
      } else {
        // this.renderPng(data)
        const imageData = this.renderPng(data);
        const dataImage = fetchData(imageData);
        // console.log(dataImage)
        img.file(`${data.name}.png`, dataImage, { base64: true });

        this.addGenerating(1);
      }
    });
  }

  setDoorState(state) {
    if (state) {
      this.leftDoor.rotation.set(0, 0, 0);
      this.rightDoor.rotation.set(0, 0, 0);
      this.leftTrunkDoor.rotation.set(0, 0, 0);
      this.rightTrunkDoor.rotation.set(0, 0, 0);
      this.backTrunkDoor.rotation.set(0, 0, 0);
    } else {
      if (this.doorOpen) {
        this.leftDoor.rotation.set(0.2, -1.3, 0);
        this.rightDoor.rotation.set(0.2, 1.3, 0);
      }

      if (this.truckOpen) {
        this.leftTrunkDoor.rotation.set(0, -2.3, 0);
        this.rightTrunkDoor.rotation.set(0, 2.3, 0);
        this.backTrunkDoor.rotation.set(-1.3, 0, 0);
      }
    }
  }

  renderFeatureImage() {
    // this.doorOpen = false;
    // this.truckOpen = false;
    // this.cameraState = 'outside';

    this.setDoorState(true);

    // console.log(this.doorOpen)
    // console.log(this.truckOpen)

    const type = this.configureData.carType;
    this.previewCamera.aspect = 1800 / 900;
    this.previewCamera.updateProjectionMatrix();
    this.previewRenderer.setPixelRatio(window.devicePixelRatio);
    this.previewRenderer.setSize(1200, 600);
    this.previewRenderer.render(this.scene, this.previewCamera);

    // 1200x600

    // console.log({ type })
    // this.camera.aspect = window.innerWidth / window.innerHeight;
    // this.camera.updateProjectionMatrix();
    // this.renderer.setSize(window.innerWidth, window.innerHeight);

    // console.log(this.logoTexture.logofileName)
    const hasLogo = this.logoTexture.logofileName;

    const imageData = this.renderPng({
      position:
        type === 'van' && hasLogo
          ? new THREE.Vector3(3, 1.4, 0)
          : new THREE.Vector3(2, 1.4, 2.7),
      name: 'AIO Vehicle Email Featured Image',
    });

    // localStorage.setItem('featureImageEmail', immageData);

    // var link = document.createElement('a');
    // link.setAttribute('href', imageData);
    // link.setAttribute('target', '_blank');
    // link.setAttribute('download', 'AIO Vehicle Email Featured Image');
    // link.click();
    // this.setGenerating(false)
    this.setDoorState(false);

    return imageData;

    // console.log(localStorage);
  }

  switchCar(name) {
    // console.log(name)
    this.suv.visible = false;
    this.truck.visible = false;
    this.van.visible = false;
    switch (name) {
      case 'car':
        this.suv.visible = true;
        break;
      case 'truck':
        this.truck.visible = true;
        break;
      case 'van':
        this.van.visible = true;
        break;
      default:
        break;
    }
    this.configureData.carType = name;
    const newData = {
      ...this.configureData,
    };
    this.setConfigureData(newData);
  }

  showHideHotSpot() {
    // console.log(this.cameraState);

    if (this.cameraState === 'front') {
      this.frontLable.visible = false;
      this.backLable.visible = true;
      this.screenLable.visible = true;
      this.setIsInterior(true);
    } else if (this.cameraState === 'back') {
      this.frontLable.visible = true;
      this.backLable.visible = false;
      this.screenLable.visible = false;
      this.setIsInterior(true);
    } else {
      this.setIsInterior(false);
      if (!this.doorOpen && !this.truckOpen) {
        this.frontLable.visible = false;
        this.backLable.visible = false;
        this.screenLable.visible = false;
      } else {
        this.frontLable.visible = true;
        this.backLable.visible = true;
        this.screenLable.visible = false;
      }
    }
  }

  renderPng(data) {
    this.previewCamera.position.copy(data.position);
    this.previewCamera.lookAt(0, 0.7, 0);

    this.lensflare.visible = false;
    this.car &&
      this.car.traverse((child) => {
        if (child instanceof THREE.Mesh && !child.userData.light) {
          child.material = this.darkMaterial;
        }
      });
    this.skybox.visible = false;
    this.previewBloomComposer.render();

    this.skybox.visible = true;
    // this.skybox.visible = true
    this.lensflare.visible = false;
    this.previewRenderer.render(this.scene, this.previewCamera);

    this.scene.background = new THREE.Color(0xffffff);

    this.lensflare.visible = false;
    this.car &&
      this.car.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.material = child.userData.defaultMatt;
        }
      });

    // const img = zip.folder("images");

    // this.previewBloomComposer.render()
    // // // this.renderer.render(this.scene, this.previewCamera)
    // // this.renderer.render(this.scene, this.previewCamera);
    this.previewComposer.render();

    this.scene.background = null;
    const canvas = this.previewRenderer.domElement;
    const imgData = canvas.toDataURL();
    return imgData;

    // var link = document.createElement('a');
    // link.setAttribute('href', imgData);
    // link.setAttribute('target', '_blank');
    // link.setAttribute('download', data.name);
    // link.click();
  }

  activeMaterial(material) {
    const activeMaterial = this.configureMaterials[material];
    activeMaterial.emissive = new THREE.Color(0xffffff);
    activeMaterial.emissiveIntensity = 0;
    const duration = 0.3;
    var tl = gsap.timeline();
    tl.to(activeMaterial, {
      duration: duration,
      ease: 'sine.inOut',
      emissiveIntensity: 0.5,
    });
    // tl.call(() => { }, null, "+=0.5");
    tl.to(activeMaterial, {
      duration: duration,
      ease: 'sine.inOut',
      emissiveIntensity: 0,
    });
    tl.to(activeMaterial, {
      duration: duration,
      ease: 'sine.inOut',
      emissiveIntensity: 0.5,
    });
    tl.to(activeMaterial, {
      duration: duration,
      ease: 'sine.inOut',
      emissiveIntensity: 0,
    });
    tl.to(activeMaterial, {
      duration: duration,
      ease: 'sine.inOut',
      emissiveIntensity: 0.5,
    });
    tl.call(() => {}, null, '+=0.2');
    tl.to(activeMaterial, {
      duration: duration,
      ease: 'sine.inOut',
      emissiveIntensity: 0,
    });
  }
  updateMaterial(data) {
    this.setLoadingTexture(true);
    console.log(data);
    this.configureData.colors[data.mode].material = data.material.title;

    console.log(data);
    const activeMaterial = this.configureMaterials[data.mode];
    const file = data.material.file;
    console.log(file);

    this.gltfLoader.load(
      data.material.file,
      function (gltf) {
        gltf.scene.traverse((obj) => {
          if (obj instanceof THREE.Mesh) {
            const loadMatt = obj.material;
            console.log(gltf, loadMatt);
            for (const key in activeMaterial) {
              if (key != 'color') {
                activeMaterial[key] = loadMatt[key];
              }
            }
            this.setLoadingTexture(false);
            return;
          }
        });
      }.bind(this)
    );
    const newData = {
      ...this.configureData,
    };

    this.setConfigureData(newData);
  }

  updateColor(data) {
    // console.log(data)
    const { material, color } = data;

    this.configureData.colors[material].color = color;

    const newData = {
      ...this.configureData,
    };

    this.setConfigureData(newData);

    // console.log({ newData })

    const newColor = new THREE.Color(color);
    if (this.configureMaterials[material]) {
      this.configureMaterials[material].color = newColor;
    }

    // const comp = this.getComplementaryColor(value)

    // this.configureMaterials[material].sheenColor = new THREE.Color(comp)
  }

  enterInterior() {
    this.controls.maxPolarAngle = 3;
    this.controls.minDistance = 0;
    gsap.to(this.controlCamera.position, {
      duration: 1,
      ease: 'sine.inOut',
      x: 0,
      y: 1.2,
      z: -0.2,
      onUpdate: () => {
        this.controls.update();
      },
      onComplete: () => {
        // this.controls.maxPolarAngle = 3
        // this.controls.minPolarAngle = 0.2
        // this.controls.enableZoom = false
        this.scene.attach(this.controlCamera);
        this.scene.attach(this.camera);
        this.controls.enabled = true;
        this.cameraState = 'front';
        this.controls.maxDistance = this.contronMaxDistanceNear;

        this.screenLable.visible = true;
        this.showHideHotSpot();

        if (this.firstVideoPlay) {
          this.firstVideoPlay = false;
          this.powerOn();
        }
      },
    });

    gsap.to(this.controls.target, {
      duration: 1,
      ease: 'sine.inOut',
      x: 0,
      y: 1.2,
      z: 0,
    });
  }

  moveToScreen() {
    gsap.to(this.controls.target, {
      duration: 1,
      ease: 'sine.inOut',
      x: -0.2,
      y: 1.05,
      z: 0.65,
    });

    // (-0.3, 1.05, 0.65);

    gsap.to(this.controlCamera.position, {
      duration: 1,
      ease: 'sine.inOut',
      x: -0.563,
      y: 1.178,
      z: 0.268,
      onUpdate: () => {
        this.controls.update();
      },
      onComplete: () => {
        this.frontLable.visible = true;
      },
    });
  }

  enterBackTruck() {
    this.controls.maxPolarAngle = 3;
    this.controls.minDistance = 0;
    gsap.to(this.controlCamera.position, {
      duration: 1,
      ease: 'sine.inOut',
      x: 0,
      y: 1.2,
      z: -1.05,
      onUpdate: () => {
        this.controls.update();
      },
      onComplete: () => {
        // this.controls.maxPolarAngle = 3
        // this.controls.minPolarAngle = 0.2
        // this.controls.enableZoom = false
        this.controls.maxDistance = this.contronMaxDistanceNear;

        this.scene.attach(this.controlCamera);
        this.scene.attach(this.camera);
        this.controls.enabled = true;
        this.frontSpriteGrp.visible = true;
        this.backSpriteGrp.visible = false;
        this.cameraState = 'back';
        this.showHideHotSpot();
      },
    });

    gsap.to(this.controls.target, {
      duration: 1,
      ease: 'sine.inOut',
      x: 0,
      y: 1.2,
      z: -0.8,
    });
  }

  swichInteroir(value) {
    console.log(value);
    this.controls.enabled = false;
    if (value) {
      this.configureData.isInterior = true;

      const newData = {
        ...this.configureData,
      };
      this.setConfigureData(newData);
      this.controls.maxPolarAngle = 3;
      this.controls.minDistance = 0;
      this.updateCameraPosition('door');
    } else {
      let position = null;

      if (this.cameraState === 'back') {
        position = new THREE.Vector3(0, 1.5, -4);
      } else {
        position = new THREE.Vector3(3.5, 1.5, 0);
      }

      this.cameraState = 'outside';
      this.controls.maxPolarAngle = 1.5;
      this.controls.maxDistance = this.contronMaxDistance;
      // this.controls.minDistance = this.contronMimDistance
      gsap.to(this.controlCamera.position, {
        duration: 2,
        ease: 'sine.inOut',
        x: position.x,
        y: position.y,
        z: position.z,
        onUpdate: () => {
          this.controls.update();
        },
        onComplete: () => {
          // this.controls.maxPolarAngle = 3
          // this.controls.minPolarAngle = 1.1
          this.controls.enableZoom = true;

          this.controls.enabled = true;
          this.controls.maxDistance = this.contronMaxDistance;
          this.controls.minDistance = this.contronMimDistance;
          this.configureData.isInterior = false;
          const newData = {
            ...this.configureData,
          };
          this.setConfigureData(newData);
          this.showHideHotSpot();
        },
      });

      gsap.to(this.controls.target, {
        duration: 2,
        ease: 'n',
        x: 0,
        y: 0,
        z: 0,
      });
    }
  }

  openCloseDoor(state) {
    gsap.to([this.leftDoor.rotation], {
      duration: 1,
      ease: 'power3.out',
      x: state ? 0.2 : 0,
      y: state ? -1.3 : 0,
      z: 0,
    });

    // this.leftTrunkDoor = gltf.scene.getObjectByName("left_trunk_door")
    // this.rightTrunkDoor = gltf.scene.getObjectByName("right_trunk_door")
    // this.backTrunkDoor = gltf.scene.getObjectByName("back_trunk_door")

    gsap.to([this.rightDoor.rotation], {
      duration: 1,
      ease: 'power3.out',
      x: state ? 0.2 : 0,
      y: state ? 1.3 : 0,
      z: 0,
      onComplete: () => {
        console.log(this.cameraState);
        this.showHideHotSpot();
      },
    });

    // if(state){
    // }
    this.doorOpen = state;
  }

  openCloseBackTrunk(state) {
    this.truckOpen = state;

    gsap.to(this.leftTrunkDoor.rotation, {
      duration: 1,
      ease: state ? 'power3.out' : 'none',
      x: 0,
      y: state ? -2.3 : 0,
      z: 0,
    });

    gsap.to(this.rightTrunkDoor.rotation, {
      duration: 1,
      ease: state ? 'power3.out' : 'none',
      x: 0,
      y: state ? 2.3 : 0,
      z: 0,
    });

    gsap.to(this.backTrunkDoor.rotation, {
      duration: 1,
      ease: state ? 'power3.out' : 'none',
      x: state ? -1.3 : 0,
      y: 0,
      z: 0,
      onComplete: () => {
        this.showHideHotSpot();
      },
    });
  }

  updateCameraPosition(name) {
    let rotationAngle = 0;
    let targetVector = new THREE.Vector3();

    switch (name) {
      case 'back-left':
        rotationAngle = -2.5;
        targetVector.set(1, 0, -1);
        this.cameraTagetRef.lookAt(new THREE.Vector3(1, 0, -1));
        this.targetQuaternion.setFromAxisAngle(
          new THREE.Vector3(0, 1, 0),
          -2.5
        );
        break;
      case 'back-right':
        rotationAngle = 2.5;
        targetVector.set(-1, 0, -1);
        this.cameraTagetRef.lookAt(new THREE.Vector3(-1, 0, -1));
        this.targetQuaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), 2.5);
        break;
      case 'front-left':
        rotationAngle = -0.5;
        targetVector.set(1, 0, 1);
        this.cameraTagetRef.lookAt(new THREE.Vector3(1, 0, 1));
        this.targetQuaternion.setFromAxisAngle(
          new THREE.Vector3(0, 1, 0),
          -0.5
        );
        break;
      case 'front-right':
        rotationAngle = 0.5;
        targetVector.set(-1, 0, 1);
        this.cameraTagetRef.lookAt(new THREE.Vector3(-1, 0, 1));
        this.targetQuaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), 0.5);
        break;
      case 'door':
        rotationAngle = -1.5;
        // console.log(this.camera.position)
        targetVector.set(this.camera.position.x > 0 ? 1 : -1, 0, 0);
        this.cameraTagetRef.lookAt(
          new THREE.Vector3(this.camera.position.x > 0 ? 1 : -1, 0, 0)
        );
        this.targetQuaternion.setFromAxisAngle(
          new THREE.Vector3(0, 1, 0),
          -0.5
        );
        break;
      case 'back':
        rotationAngle = -1.5;
        // console.log(this.camera.position)
        targetVector.set(0, 0, -1);
        this.cameraTagetRef.lookAt(new THREE.Vector3(0, 0, -1));
        this.targetQuaternion.setFromAxisAngle(
          new THREE.Vector3(0, 1, 0),
          -0.5
        );
        break;

      default:
        break;
    }
    this.controls.enabled = false;
    this.rotationTaget.lookAt(
      this.controlCamera.position.x,
      0,
      this.controlCamera.position.z
    );
    this.rotationTaget.attach(this.controlCamera);
    this.rotationTaget.attach(this.camera);

    gsap.to(this.rotationTaget.rotation, {
      duration: 1.5,
      ease: 'sine.inOut',
      y: this.cameraTagetRef.rotation.y,
      onUpdate: () => {},
      onComplete: () => {
        this.scene.attach(this.controlCamera);
        this.scene.attach(this.camera);
        if (name === 'door') {
          this.enterInterior();
        } else if (name === 'back') {
          this.enterBackTruck();
        } else {
          this.controls.enabled = true;
        }
      },
    });

    // console.log(this.rotationTaget.rotation)

    gsap.to(this.controlCamera.position, {
      duration: 1,
      ease: 'sine.inOut',
      y: 1.5,
    });
  }

  async enterAR(mode) {
    if (mode === 'ios') {
      const exporter = new USDZExporter();
      this.screen.material.map = this.screenTexture;

      exporter.parse(this.car).then((object) => {
        const blob = new Blob([object], { type: 'application/octet-stream' });
        const anchor = document.createElement('a');
        anchor.setAttribute('href', '');
        anchor.href = URL.createObjectURL(blob);
        anchor.setAttribute('download', 'aio.usdz');
        anchor.setAttribute('rel', 'ar');
        anchor.appendChild(document.createElement('img'));
        anchor.click();
      });
      this.screen.material.map = this.videoTexture;
    } else if (mode === 'webxr') {
      console.log('enter AR using WebXR API');
      this.skybox.visible = false;
      cancelAnimationFrame(this.animateFrame);
      this.webxr.startAR();
      this.skybox.visible = false;
    } else {
      console.log('AR Not supported');
    }
  }

  showHideCover(value) {
    // this.aeroCover.visible = value

    this.hideCover(value);
    this.configureData.aeroCover = value;
    const newData = {
      ...this.configureData,
    };
    this.setConfigureData(newData);
  }
  updateWheel(data) {
    const color = new THREE.Color(data);
    // this.wheel.material.color = color
    // // console.log(this.wheel)

    // const { material, color } = data

    // this.configureData.colors[material].color = color

    // const newData = {
    //     ...this.configureData,
    // }

    // this.setConfigureData(newData)
  }
  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.labelRenderer.setSize(window.innerWidth, window.innerHeight);
  }

  animate() {
    // console.log(this.camera)
    // console.log("rendering")
    // this.renderer.render(this.scene, this.camera);
    this.animateFrame = requestAnimationFrame(this.animate.bind(this));
    this.controls.update();

    this.camera.position.lerp(this.controlCamera.position, 0.1);
    this.camera.quaternion.slerp(this.controlCamera.quaternion, 0.1);

    // this.rotationTaget.quaternion.slerp(this.targetQuaternion, 0.05)
    // if (!this.slerpEnd) {
    //     console.log("detection")
    //     const cutOff = this.rotationTaget.quaternion.angleTo(this.cameraTagetRef.quaternion)
    //     console.log(cutOff)
    //     // console.log(this.cameraTagetRef.rotation)
    //     // console.log(this.rotationTaget.rotation)
    //     if (cutOff < 0.01) {
    //         this.slerpEndEvent()
    //     }
    // }

    // this.frontSprite.visible = false
    // this.backSprite.visible = false
    this.lensflare.visible = true;
    this.car &&
      this.car.traverse((child) => {
        if (child instanceof THREE.Mesh && !child.userData.light) {
          child.material = this.darkMaterial;
        }
      });
    if (this.skybox) {
      this.skybox.visible = false;
    }

    this.bloomComposer.render();
    if (this.skybox) {
      this.skybox.visible = true;
    }

    // this.lensflare.visible = true
    this.renderer.render(this.scene, this.camera);
    // this.frontSprite.visible = true
    // this.backSprite.visible = true
    this.lensflare.visible = false;
    this.car &&
      this.car.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.material = child.userData.defaultMatt;
        }
      });

    // render the entire scene, then render bloom scene on top
    this.finalComposer.render();

    this.labelRenderer.render(this.scene, this.camera);

    // this.renderer.render(this.scene, this.camera);
  }

  getComplementaryColor = (color = '') => {
    const colorPart = color.slice(1);
    const ind = parseInt(colorPart, 16);
    let iter = ((1 << (4 * colorPart.length)) - 1 - ind).toString(16);
    while (iter.length < colorPart.length) {
      iter = '0' + iter;
    }
    return '#' + iter;
  };
}

export default ThreejsModule;
