import * as THREE from "three";
import { DragControls } from 'three/addons/controls/DragControls.js';


export class logoTextureBuilder {
    constructor(data) {
        this.logoCanvas = data.logoCanvas;
        this.size = data.size;

        this.logoScale = data.scaleInput;
        this.prevScale = 1
        this.imgRatio = 1
        this.logofileName = data.logoFile.split("/").pop();

        // console.log( this.logofileName )

        this.enableSelection = false

        // console.log(data.logoFile)
        this.uploadImg = new Image();
        this.uploadImg.src = data.logoFile;

        this.position = data.position || new THREE.Vector3()
        this.scale = data.scale || 1

        this.initLoad = true

        this.fileInput = data.fileInput;

        this.textureLoader = new THREE.TextureLoader();

        this.scene = new THREE.Scene();

        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            preserveDrawingBuffer: true,
            canvas: data.logoCanvas,
        });

        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.size.width, this.size.height);
        this.renderer.outputEncoding = THREE.sRGBEncoding;


        this.camera = new THREE.OrthographicCamera(this.size.width / - 2, this.size.width / 2, this.size.height / 2, this.size.height / - 2, 1, 100);

        this.camera.position.z = 1

        this.camera.aspect = this.logoCanvas.clientWidth / this.logoCanvas.clientHeight;
        this.camera.updateProjectionMatrix();


        // finalCamera


        this.finalCamera = new THREE.OrthographicCamera(this.size.width / - 2, this.size.width / 2, this.size.width / 2, this.size.width / - 2, 1, 100);
        this.finalCamera.position.z = 1
        this.finalCamera.aspect = 1;
        this.finalCamera.updateProjectionMatrix();

        const objects = [];
        this.logoMaterial = new THREE.MeshBasicMaterial({ map: null, transparent: true, opacity: 0 });
        this.plane = new THREE.Mesh(new THREE.PlaneGeometry(this.size.height - 10, this.size.height - 10), this.logoMaterial);
        this.scene.add(this.plane)


        this.controls = new DragControls([this.plane], this.camera, this.renderer.domElement);

        this.controls.addEventListener('drag', this.render.bind(this));


        // plane.rotateX(-Math.PI / 2)
        // remove-logo


        document.addEventListener('click', this.onClick.bind(this));
        window.addEventListener('keydown', this.onKeyDown.bind(this));
        window.addEventListener('keyup', this.onKeyUp.bind(this));

        // this.logoScale.onpointerdown = (e) => {
        //     this.prevScale = this.plane.scale.y
        // };
        // this.logoScale.onpointerup = (e) => {
        //     // this.bannerSlider.value = 50;
        //     e.target.value = 50
        // };

        // this.logoScale.addEventListener("input", (e) => {
        //     // console.log(e);
        //     const scale = Number(e.target.value) / 50;

        //     // console.log(scale);

        //     // console.log(this.prevScale)

        //     const deltaScale = this.prevScale * scale

        //     this.plane.scale.set(deltaScale * this.imgRatio, deltaScale, deltaScale)

        //     this.render()

        //     // const scale = Number(e.target.value) / 50;
        //     // if (scale !== this.prevScale) {
        //     //     this.bannerData.width = this.initWidth * scale;
        //     //     this.bannerData.height = this.initHeight * scale;
        //     //     this.renderBackdrop();
        //     // }

        //     // this.prevScale = scale;

        // });



        this.logoFileReader = new FileReader()
        this.logoFileReader.onload = (e) => {
            // console.log(e)
            const loadFIle = this.logoFileReader.result
            this.uploadImg.src = loadFIle;
            this.loadLogo(loadFIle)
        }

        // console.log(this.fileInput)

        document.querySelector("#logo-file-input").addEventListener("change", (e) => {
            // console.log(e);
            this.logofileName = e.target.files[0].name;
            // console.log( this.logofileName )
            this.logoFileReader.readAsDataURL(e.target.files[0]);
        });

        // document.querySelector("#remove-logo").addEventListener("click", (e) => {
        //    console.log("remove")
        //     this.logoMaterial = new THREE.MeshBasicMaterial({ map: null, transparent: true, opacity: 0 });
        //     this.plane.material = this.logoMaterial
        //     this.render()
        // });






        this.finalRenderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            preserveDrawingBuffer: true,
        });
        this.finalRenderer.setPixelRatio(window.devicePixelRatio);
        this.finalRenderer.setSize(1024, 1024);
        this.finalRenderer.outputEncoding = THREE.sRGBEncoding;

        this.texture = new THREE.CanvasTexture(this.finalRenderer.domElement);
        this.texture.flipY = false;
        this.texture.encoding = THREE.sRGBEncoding;
        this.texture.needsUpdate = true;

        this.onFinalRender = () => { }
        this.render()

    }

    getFileName(url) {
        const urlList = url.split("/");

        const name = urlList[urlList.length - 1]



    }

    onScale(value) {
        const scale = Number(value) / 50;

        const deltaScale = this.prevScale * scale

        this.plane.scale.set(deltaScale, deltaScale * this.imgRatio, deltaScale)

        this.render()
    }
    onScaleStart() {
        console.log("scale start")
        this.prevScale = this.plane.scale.x



    }
    initSize(position, scale) {
        this.plane.position.copy(position)
        this.plane.scale.set(this.imgRatio * scale, scale, 1)

    }
    removeLogo() {

        this.logoMaterial = new THREE.MeshBasicMaterial({ map: null, transparent: true, opacity: 0 });
        this.plane.material = this.logoMaterial
        this.render()
        this.logofileName = null
    }

    loadLogo(file) {
        this.textureLoader.load(file, (image) => {
            image.encoding = THREE.sRGBEncoding;
            this.logoMaterial = new THREE.MeshBasicMaterial({ map: image, transparent: true });
            // this.logoMaterial.map = image

            this.plane.material = this.logoMaterial
            this.imgRatio = image.image.height / image.image.width
            // console.log(this.imgRatio)

            if (this.initLoad) {
                this.plane.position.copy(this.position)
                this.plane.scale.set(this.scale, this.imgRatio * this.scale, 1)
            } else {
                this.plane.position.set(0, 0, 0)
                this.plane.scale.set(this.scale, this.imgRatio * this.scale, 1)
            }

            this.render()
            // console.log(image)

            this.initLoad = false
        });

    }
    onClick(event) {
        // event.preventDefault();
        // console.log(this.enableSelection)
        // const draggableObjects = this.controls.getObjects();
        // // draggableObjects.length = 0;
        // console.log(draggableObjects)
        // // this.render().bind(this);
    }
    onKeyDown(event) {
        this.enableSelection = true
    }
    onKeyUp() {
        this.enableSelection = false;
    }

    render() {
        this.renderer.render(this.scene, this.camera)
        this.conpositeRender()
        // console.log(this.plane.position)
    }
    conpositeRender() {
        this.finalRenderer.render(this.scene, this.finalCamera)
        // this.logoCanvasCtx.restore();
        this.texture.needsUpdate = true;
        const position = this.plane.position
        const scale = this.plane.scale.y
        this.onFinalRender(this.texture, position, scale)
    }
}