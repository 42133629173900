const MaterialDataSet = [
    {
        title: "Gloss",
        id: 0,
        preview: "./assets/materials/gloss.png",
        file: "./assets/materials/gloss.glb"
    },
    {
        title: "Matt",
        id: 1,
        preview: "./assets/materials/matt.png",
        file: "./assets/materials/matt.glb"
    },
    {
        title: "carpet",
        id: 0,
        preview: "./assets/materials/carpet.png",
        file: "./assets/materials/carpet.glb"
    },
    {
        title: "fabric suit",
        id: 1,
        preview: "./assets/materials/fabric_suit_vintage.png",
        file: "./assets/materials/fabric_suit_vintage.glb"
    },
    {
        title: "leather-wrap",
        id: 2,
        preview: "./assets/materials/leather-wrap.png",
        file: "./assets/materials/leather-wrap.glb"
    },
    {
        title: "marble",
        id: 3,
        preview: "./assets/materials/marblesubstance001.png",
        file: "./assets/materials/marblesubstance001.glb"
    },
    {
        title: "Leather",
        id: 4,
        preview: "./assets/materials/NaturalLeather.png",
        file: "./assets/materials/NaturalLeather.glb"
    },
    {
        title: "Pelle chiara",
        id: 5,
        preview: "./assets/materials/Pelletraforatachiara.png",
        file: "./assets/materials/Pelletraforatachiara.glb"
    },
    {
        title: "brillant",
        id: 5,
        preview: "./assets/materials/Plastique-brillant.png",
        file: "./assets/materials/Plastique-brillant.glb"
    },
    {
        title: "Wood",
        id: 5,
        preview: "./assets/materials/SimpleFlinishWood.png",
        file: "./assets/materials/SimpleFlinishWood.glb"
    },
    {
        title: "Steel",
        id: 5,
        preview: "./assets/materials/SteelPainted.png",
        file: "./assets/materials/SteelPainted.glb"
    },
    {
        title: "Tiles",
        id: 5,
        preview: "./assets/materials/Tiles.png",
        file: "./assets/materials/Tiles.glb"
    },
]


export default MaterialDataSet