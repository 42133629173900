import React, { useContext } from 'react';
import './styles/reservationStyles.css'; // Import the stylesheet
import './styles/FleetForm.css';
import AIOContext from '../../AIOContext';
import formatCurrency from '../../util/formatCurrency';
import { capitalizeFirstLetter } from '../../util/textUtils';

function Overview() {
  const { user, dap, fleet, vehicle } = useContext(AIOContext);

  const { dapChecked, toggleDap, totalDapAmount } = dap;

  const { totalVehicles, totalVehicleTypesSelected, vehicleSubtotal } = fleet;

  const { vehicleConfig, setVehicleConfig } = vehicle;
  console.log(vehicleConfig);

  const { vehicleQuantities } = vehicleConfig;

  const vehicleTypeAliases = {
    car: 'AIO CAR™',
    truck: 'AIO TRUCK™',
    van: 'AIO VAN™',
  };

  const handleQuantityChange = (newValue, vehicleType) => {
    const re = /^[0-9\b]+$/;

    if (re.test(newValue)) {
      setVehicleConfig((prevConfig) => ({
        ...prevConfig,
        vehicleQuantities: {
          ...prevConfig.vehicleQuantities,
          [vehicleType]: isNaN(newValue) ? 0 : parseInt(newValue, 10),
        },
      }));

      return;
    } else {
      setVehicleConfig((prevConfig) => ({
        ...prevConfig,
        vehicleQuantities: {
          ...prevConfig.vehicleQuantities,
          [vehicleType]: 0,
        },
      }));
      return;
    }
  };

  return (
    <React.Fragment>
      <div className='form-panel'>
        <h2 className='font-wide text-3xl font-extrabold tracking-[0.03125rem] mb-14'>
          Reservation Overview
        </h2>

        <div className='flex flex-col gap-6 pb-10'>
          <div className='font-wide text-2xl font-extrabold uppercase'>
            {vehicleConfig.isFleet
              ? 'AIO Fleet'
              : 'AIO ' + vehicleConfig.carType}
            {'™'}
          </div>

          <div className='text-neutral-900 text-sm font-normal leading-normal tracking-wide uppercase'>
            The Single-Occupant Electric{' '}
            {vehicleConfig.isFleet ? 'Fleet' : vehicleConfig.carType}
          </div>
        </div>

        <hr />

        {vehicleConfig.isFleet && (
          <React.Fragment>
            <div className='pt-10 pb-6 flex flex-col gap-6'>
              <div className='text-2xl font-extrabold font-wide uppercase tracking-[0.03125rem]'>
                Vehicle Quantity
              </div>
              <div className='vehicleQauntContainer'>
                <div className='flex justify-between items-center gap-6 text-sm font-extrabold font-wide'>
                  <div>Type</div>
                  <div>Quantity</div>
                </div>
              </div>
              <div className='fleetContainer no-p'>
                {Object.keys(vehicleQuantities).map((vehicleType) => (
                  <div className='vehicleAmountContainer' key={vehicleType}>
                    <div className='left vehicle-aliases'>
                      <span>{vehicleTypeAliases[vehicleType]}</span>
                    </div>
                    <div className='right clear-new-button'>
                      <button
                        className='clear-button cursor-pointer font-wide'
                        onClick={() => handleQuantityChange(0, vehicleType)} // Clear to 0
                      >
                        Clear
                      </button>
                      <div className='number-container'>
                        <input
                          type='text'
                          className='quantity-input'
                          inputMode='numeric'
                          value={vehicleQuantities[vehicleType]}
                          onChange={(e) =>
                            handleQuantityChange(e.target.value, vehicleType)
                          }
                          onBlurCapture={(e) => {
                            if (e.target.value === '') {
                              handleQuantityChange(0, vehicleType);
                            }
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div className='pt-6 pb-10'>
              <div className='flex justify-between gap-6 text-lg font-bold font-wide'>
                <div>Total Vehicles</div>
                <div>{totalVehicles}</div>
              </div>
            </div>

            <hr />
          </React.Fragment>
        )}

        <div className='pt-10 flex flex-col mb-10'>
          {vehicleConfig.isFleet && (
            <div className='pb-6'>
              <div className='text-2xl font-extrabold font-wide tracking-[0.03125rem]'>
                Reservation Cost
              </div>
            </div>
          )}
          <div className='flex justify-between gap-6'>
            <div className='flex flex-col gap-[10px]'>
              <div className='text-lg font-bold font-wide flex gap-2 items-center'>
                Reservation{' '}
                {!vehicleConfig.isFleet && (
                  <div className='text-small inline-flex'>(Refundable)</div>
                )}
              </div>
              {vehicleConfig.isFleet && (
                <div className='text-small'>(Refundable)</div>
              )}
            </div>
            <div className='flex flex-col gap-[10px] items-end'>
              <div className='text-lg font-bold font-wide'>
                {formatCurrency(vehicleSubtotal)}
              </div>
              {vehicleConfig.isFleet && (
                <div className='text-small'>
                  Vehicle Quantity ({totalVehicles}) x $100ea
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className='dap p-6 rounded-lg bg-[#F5F5F5] border-[#F1F1F1] cursor-pointer user-select-none'
          onClick={() => {
            toggleDap();
          }}
        >
          <div className='flex flex-col gap-4'>
            <div>
              <div className='chip'>Recommended</div>
            </div>

            <div>
              <div className='flex justify-between gap-6  text-[1.125rem] font-bold font-wide'>
                <div>
                  <label
                    htmlFor='dapCheckbox'
                    className='flex gap-3 items-center cursor-pointer pointer-events-none'
                  >
                    <input
                      type='checkbox'
                      id='dapCheckbox'
                      name='dapCheckbox'
                      checked={dapChecked}
                      onChange={() => {
                        toggleDap();
                      }}
                    />{' '}
                    Digital Asset Package - DAP
                  </label>
                </div>
                <div>{formatCurrency(totalDapAmount)}</div>
              </div>

              {vehicleConfig.isFleet && (
                <div className='text-right text-[0.75rem] tracking-[0.0625rem] pt-3 font-wide'>
                  Vehicle Types ({totalVehicleTypesSelected}) x $10ea
                </div>
              )}
            </div>

            <div className='text-[0.75rem] font-normal tracking-[0.03125rem] leading-[1.125rem] text-[#383A3A]'>
              <div>AIO PICTURES™ artificially intelligent movie studio:</div>
              <ul className='my-0'>
                <li>Ultra High Definition Images of AIO CUSTOM™</li>
              </ul>
            </div>
          </div>
        </div>

        <hr />
        <div className='pt-10 flex flex-col gap-6'>
          <div className='text-2xl font-extrabold font-wide tracking-[0.03125rem]'>
            Personal Details
          </div>

          <form className='flex flex-col gap-6'>
            <div className='grid lg:grid-cols-2 gap-[10px]'>
              <div className=''>
                <label className='reservation-label' htmlFor='firstName'>
                  First Name
                </label>
                <input
                  className='reservation-input'
                  name='firstName'
                  id='firstName'
                  type='text'
                  autoComplete='given-name'
                  placeholder='Enter First Name'
                  value={user.userConfig.firstName}
                  onChange={(e) => {
                    const name = e.target.name;
                    const value = e.target.value;
                    user.setUserConfig((prevState) => ({
                      ...prevState,
                      [name]: value,
                    }));
                  }}
                  required
                />
              </div>
              <div className=''>
                <label className='reservation-label' htmlFor='lastName'>
                  Last Name
                </label>
                <input
                  className='reservation-input'
                  name='lastName'
                  id='lastName'
                  type='text'
                  autoComplete='family-name'
                  placeholder='Enter Last Name'
                  value={user.userConfig.lastName}
                  onChange={(e) => {
                    const name = e.target.name;
                    const value = e.target.value;
                    user.setUserConfig((prevState) => ({
                      ...prevState,
                      [name]: value,
                    })); // Pass data to parent component (FleetForm)
                  }}
                  required
                />
              </div>
            </div>

            {/* Email Address input field */}
            <div className=''>
              <label className='reservation-label' htmlFor='email'>
                Email
              </label>
              <input
                className='reservation-input'
                name='emailAddress'
                id='email'
                type='email'
                autoComplete='email'
                placeholder='Enter Email Address'
                value={user.userConfig.emailAddress}
                onChange={(e) => {
                  const name = e.target.name;
                  const value = e.target.value;
                  user.setUserConfig((prevState) => ({
                    ...prevState,
                    [name]: value,
                  })); // Pass data to parent component (FleetForm)
                }}
                required
              />
            </div>

            {vehicleConfig.isFleet && (
              <div className=''>
                <label className='reservation-label' htmlFor='companyName'>
                  Company
                </label>
                <input
                  className='reservation-input'
                  name='companyName'
                  id='companyName'
                  type='text'
                  placeholder='Enter Company Name'
                  autoComplete='organization'
                  value={user.userConfig.companyName}
                  onChange={(e) => {
                    const name = e.target.name;
                    const value = e.target.value;
                    user.setUserConfig((prevState) => ({
                      ...prevState,
                      [name]: value,
                    })); // Pass data to parent component (FleetForm)
                  }}
                  required
                />
              </div>
            )}

            <div className='terms font-wide text-[12px] tracking-[0.03125rem]'>
              By continuing, I have read and accept the{' '}
              <a
                href='https://aiomotors.com/terms-of-use'
                target='_blank'
                rel='noreferrer'
                className='underline'
              >
                Terms of Use
              </a>{' '}
              and{' '}
              <a
                href='https://aiomotors.com/privacy-policy'
                target='_blank'
                rel='noreferrer'
                className='underline'
              >
                Privacy Policy
              </a>
              .
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Overview;
