import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Avatar,
  Typography,
  Collapse,
} from '@mui/material';
import { keyframes } from '@mui/system';

const spin = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

function InitLoadingComp(props) {
  return (
    <Box
      sx={{
        cursor: 'grab',
        width: '100%',
        height: '100%',
        backgroundColor: '#212121',
        position: 'absolute',
        top: '0',
        zIndex: '100',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '16px',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Box
          component='img'
          src='./assets/images/icons/aio.svg'
          sx={{
            width: '100px',
          }}
        ></Box>
        <Typography
          sx={{
            position: 'absolute',
            fontFamily: 'Mona Sans',
            fontWeight: '800',
            fontSize: '20px',
            lineHeight: '20px',
            letterSpacing: '1px',
            color: 'white',
          }}
        >
          {props.loading}
        </Typography>
      </Box>
    </Box>
  );
}

export default InitLoadingComp;
