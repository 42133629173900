import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { Box, Snackbar, Grow, Grid, Typography, Card, CardActions, CircularProgress, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { keyframes } from '@mui/system';
import { nanoid } from 'nanoid'
import { QRCodeSVG } from 'qrcode.react';
import CloseComp from '../ConfigurationComp/Utility/CloseComp';

import { uploadData } from '../Modules/DataInputUpload';


function ExitComp(props) {




    const clossHandler = () => {
        props.setMode(null)
    }



    useEffect(() => {


    }, [])

    return (
        <Box
            sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: '0',
                left: '0',
                display: 'flex',
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                zIndex: "2000",
                fontFamily: 'Mona Sans',
                backgroundColor: "#000000d4"
            }}
        >

            <Grow
                in={true}
            >
                <Box
                    sx={{
                        bottom: "60px",
                        // margin: "10px 20px",
                        backgroundColor: "white",
                        borderRadius: "16px",
                        display: 'flex',
                        flexDirection: "column",
                        boxShadow: "0px 0px 6px 3px #9f9e9e47",
                        overflow: "hidden",
                        minHeight: "300px",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "25px",
                        position: 'relative',
                        padding: "10px 30px",
                        fontFamily: 'Mona Sans',

                    }}
                >
                    <CloseComp
                        onClick={clossHandler}
                        sx={{
                            position: 'absolute',
                            cursor: 'pointer',
                            right: "0",
                            top: '0',
                            margin: "16px",
                        }}
                    />

                    <Typography
                        sx={{
                            color: "#151515",
                            fontWeight: "900",
                            fontSize: "24px",
                            fontFamily: 'Mona Sans',
                            textAlign: "center",
                            width: "75%",
                            textTransform: "uppercase",
                            lineHeight: "90%",
                            letterSpacing: "1px",
                        }}

                    >
                        Are you sure
                        <br />you want to exit?</Typography>
                    <Typography
                        sx={{
                            fontSize: "14px",
                            textAlign: "center",
                            width: "75%",
                        }}
                    >If you exit the AIOVERSE experience, you will lose all of your AIO vehicle customization settings.
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                        }}
                    >

                        <Button
                            onClick={clossHandler}
                            sx={{
                                color: "#383A3A",
                                backgroundColor: "white",
                                fontSize: { xs: "10px", sm: "14px" },
                                textAlign: "center",
                                fontFamily: 'Mona Sans',
                                borderRadius: "100px",
                                margin: "15px 15px",
                                padding: "12px 20px",
                                fontWeight: "700",
                                lineHeight: "normal",
                                letterSpacing: " 0.5px",
                                textTransform: "capitalize",
                                zIndex: "1",
                                cursor: "pointer",
                                position: "relative",

                                '&::after': {
                                    content: '" "',
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    zIndex: "-1",
                                    display: 'block',
                                    cursor: "pointer",
                                    border: "1px solid #383A3A",
                                    width: '100%',
                                    height: "100%",
                                    borderRadius: "100px",
                                    backgroundColor: "white",
                                    transitionProperty: "background-color, box-shadow,transform,filter",
                                    transitionDuration: "0.5s",
                                },
                                '&:hover': {
                                    '&::after': {
                                        transform: "scale(1.1)",
                                        backgroundColor: "white",
                                    },
                                },
                            }}
                        >No, Continue Customizing</Button>
                        <Button

                            onClick={() => {
                                window.location = "https://aiomotors.com/"
                            }}
                            sx={{
                                color: "white",
                                backgroundColor: "#383A3A",
                                fontSize: { xs: "10px", sm: "14px" },
                                textAlign: "center",
                                fontFamily: 'Mona Sans',
                                borderRadius: "100px",
                                margin: "15px 15px",
                                padding: "12px 20px",
                                fontWeight: "700",
                                lineHeight: "normal",
                                letterSpacing: " 0.5px",
                                textTransform: "capitalize",
                                zIndex: "1",
                                cursor: "pointer",
                                position: "relative",

                                '&::after': {
                                    content: '" "',
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    zIndex: "-1",
                                    display: 'block',
                                    cursor: "pointer",
                                    width: '100%',
                                    height: "100%",
                                    borderRadius: "100px",
                                    backgroundColor: "#383A3A",
                                    transitionProperty: "background-color, box-shadow,transform,filter",
                                    transitionDuration: "0.5s",
                                },
                                '&:hover': {
                                    '&::after': {
                                        transform: "scale(1.1)",
                                        backgroundColor: "#383A3A",
                                    },
                                },
                            }}
                        >Yes, Exit AIOVERSE</Button>
                    </Box>
                </Box>
            </Grow >
        </Box >
    )
}

export default ExitComp


