import React, { useContext, useState } from 'react';
import AIOContext from '../../AIOContext.js';
import formatCurrency from '../../util/formatCurrency.js';
import { capitalizeFirstLetter } from '../../util/textUtils.js';

export default function PurchaseDAP({ activateEditPaymentMethod }) {
  const { user, dueAmount, dap, fleet, vehicle } = useContext(AIOContext);

  const { dapChecked, totalDapAmount } = dap;

  const { vehicleConfig } = vehicle;

  const { vehicleQuantities } = vehicleConfig;

  const { totalVehicles, totalVehicleTypesSelected } = fleet;

  const [dapExpanded, setDapExpanded] = useState(false);

  return (
    <React.Fragment>
      <div className='form-panel !pt-8'>
        <h2 className='font-wide text-3xl font-extrabold tracking-[0.03125rem] mb-8'>
          Checkout
        </h2>

        <hr />

        <div className='pt-10 pb-6'>
          <div className='font-wide text-2xl font-extrabold pb-6'>
            Purchase Overview
          </div>
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col gap-[10px]'>
              <div className='flex justify-between gap-10 items-start'>
                <button
                  className='appearance-none bg-transparent cursor-pointer flex gap-3 items-center min-h-[36px]'
                  onClick={() => setDapExpanded(!dapExpanded)}
                >
                  <div className='text-neutral-900 text-sm font-bold font-wide tracking-wide'>
                    Digital Asset Package - DAP
                  </div>
                  <svg
                    width='10'
                    height='10'
                    viewBox='0 0 10 10'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className={`${
                      dapExpanded ? 'transform rotate-180' : ''
                    } transition-transform`}
                  >
                    <g clipPath='url(#clip0_3101_24678)'>
                      <path
                        d='M5.00186 8.1249C4.71019 8.1249 4.46019 7.9999 4.29353 7.79156L0.168527 3.16656C-0.0814732 2.83323 -0.0398065 2.33323 0.293527 2.08323C0.62686 1.83323 1.08519 1.83323 1.33519 2.16656L4.91853 6.2499C4.96019 6.29156 5.00186 6.29156 5.08519 6.2499L8.66853 2.16656C8.96019 1.83323 9.41853 1.79156 9.75186 2.08323C10.0852 2.3749 10.1269 2.83323 9.83519 3.16656L9.79353 3.20823L5.71019 7.83323C5.54353 7.9999 5.25186 8.1249 5.00186 8.1249Z'
                        fill='#9A9A9A'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_3101_24678'>
                        <rect width='10' height='10' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                </button>

                <div className='flex flex-col gap-[10px] items-end'>
                  <div className='flex items-center gap-4'>
                    <div className='flex flex-col gap-[10px]'>
                      <div className='text-[1.125rem] font-bold font-wide'>
                        {formatCurrency(totalDapAmount)}
                      </div>
                    </div>
                  </div>
                  {vehicleConfig.isFleet && (
                    <div className='text-right text-[0.75rem] tracking-[0.0625rem] font-wide'>
                      Vehicle Types ({totalVehicleTypesSelected}) x $10ea
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {dapExpanded && (
          <div className='p-6 rounded-lg bg-[#F5F5F5] border-[#F1F1F1] user-select-none mb-6'>
            <div className='flex flex-col gap-4'>
              <div>
                <div className='chip'>Recommended</div>
              </div>

              <div>
                <div className='flex justify-between gap-6  text-[1.125rem] font-bold font-wide'>
                  <div>
                    <div className='flex gap-3 items-center cursor-pointer pointer-events-none'>
                      Digital Asset Package - DAP
                    </div>
                  </div>
                </div>
              </div>

              <div className='text-[0.75rem] font-normal tracking-[0.03125rem] leading-[1.125rem] text-[#383A3A]'>
                <div>AIO PICTURES™ artificially intelligent movie studio:</div>
                <ul className='my-0'>
                  <li>Ultra High Definition Images of AIO CUSTOM™</li>
                </ul>
              </div>
            </div>
          </div>
        )}

        <hr />

        <div className='pt-4 pb-10'>
          <div className='flex justify-between'>
            <div className='text-[1.125rem] font-bold font-wide'>Total</div>
            <div className='text-[1.125rem] font-bold font-wide'>
              {formatCurrency(totalDapAmount)} USD
            </div>
          </div>
        </div>

        <hr />

        <div className='pt-10 pb-12'>
          <div className='font-wide text-2xl font-extrabold pb-6'>
            Payment Details
          </div>

          <div className='flex justify-between items-center gap-4'>
            <div className='text-neutral-900 text-sm font-normal leading-normal tracking-wide'>
              Payment Method
            </div>
            <div className='flex items-center gap-4'>
              <button
                className='appearance-none cursor-pointer bg-transparent uppercase text-sm font-bold text-brand leading-snug tracking-wide inline-flex'
                onClick={() => activateEditPaymentMethod()}
              >
                Edit
              </button>

              <div className='flex items-center gap-2'>
                <div>
                  {user.userConfig.cardBrand !== ('unknown' || 'unionpay') && (
                    <img
                      src={`./assets/images/card-brands/${user.userConfig.cardBrand}.png`}
                      alt={user.userConfig.cardBrand}
                      className='h-6 w-auto border border-[#E9E9E9] inline-block border-solid rounded-[4px] overflow-hidden'
                    />
                  )}
                </div>
                <div className='text-neutral-900 text-base font-bold font-wide leading-relaxed tracking-tight'>
                  {capitalizeFirstLetter(user.userConfig.cardFunding)} ••••{' '}
                  {user.userConfig.lastFour}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
